import {
    SHOW_LOGIN,
    GET_ADMINISTRATOR_LIST,
    GET_ASSISTANT_LIST,
    GET_PROVIDER_LIST,
    GET_HYGIENIST_LIST,
    GET_CURRENT_PERSON,
    EDIT_PERSONNEL_FLAG,
    CHANGE_PERSONNEL_LIST_FLAG,
    SET_PERSONNEL_LOADING_FLAG,
    SET_PERSONNEL_DEFAULT_STATE,
    CHANGE_TYPE,
    SET_SEARCH_ADMINISTRATOR_LIST,
    SET_SEARCH_ASSISTANT_LIST,
    SET_SEARCH_PROVIDER_LIST,
    SET_SEARCH_HYGIENIST_LIST,
    SET_USER_LIST,
    SET_COLOR_ID,
    SET_NEW_FORM_TYPE,
    CHANGE_CURRENT_CLIENT_FLAG
} from "../../storeConfig/types";

/**
 * @variable INITIAL_STATE
 * @description initial value of personnel state
 */

const INITIAL_STATE = {
    userList: [],
    administratorList: [],
    assistantList: [],
    providerList: [],
    hygienistList: [],
    currentPerson: { id: '', first_name: '', last_name: '', phone: '', login: '', type: '', color_id: '' },
    personnelFlag: true,
    personnelListFlag: false,
    personnelLoadingFlag: false,
    colorId: [],
    type: 'all',
    searchAdministratorList: [],
    searchAssistantList: [],
    searchProviderList: [],
    searchHygienistList: [],
    newFormType: 0,
    currentClientFlag: false
}

/**
 * @function PersonnelReducers
 * @description update the store according to action
 */

const PersonnelReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_LIST:
            return { ...state, userList: action.payload.data }
        case GET_ADMINISTRATOR_LIST:
            return { ...state, administratorList: action.payload.data, searchAdministratorList: action.payload.data }
        case GET_ASSISTANT_LIST:
            return { ...state, assistantList: action.payload.data, searchAssistantList: action.payload.data }
        case GET_PROVIDER_LIST:
            return { ...state, providerList: action.payload.data, searchProviderList: action.payload.data }
        case GET_HYGIENIST_LIST:
            return { ...state, hygienistList: action.payload.data, searchHygienistList: action.payload.data }
        case GET_CURRENT_PERSON:
            return { ...state, currentPerson: action.payload.data }
        case EDIT_PERSONNEL_FLAG:
            return { ...state, personnelFlag: action.payload.data }
        case CHANGE_PERSONNEL_LIST_FLAG:
            return { ...state, personnelListFlag: action.payload.data }
        case SET_PERSONNEL_LOADING_FLAG:
            return { ...state, personnelLoadingFlag: action.payload.data }
        case CHANGE_TYPE:
            return { ...state, type: action.payload.data }
        case SET_PERSONNEL_DEFAULT_STATE:
            return { ...state, personnelFlag: true, type: 'all' }
        case SET_SEARCH_ADMINISTRATOR_LIST:
            return { ...state, searchAdministratorList: action.payload.data }
        case SET_SEARCH_ASSISTANT_LIST:
            return { ...state, searchAssistantList: action.payload.data }
        case SET_SEARCH_PROVIDER_LIST:
            return { ...state, searchProviderList: action.payload.data }
        case SET_SEARCH_HYGIENIST_LIST:
            return { ...state, searchHygienistList: action.payload.data }
        case SET_COLOR_ID:
            return { ...state, colorId: action.payload.data }
        case SET_NEW_FORM_TYPE:
            return { ...state, newFormType: action.payload.data }
        case CHANGE_CURRENT_CLIENT_FLAG:
            return { ...state, currentClientFlag: action.payload.data }
        case SHOW_LOGIN:
            if (!action.payload.showLogindata) {
                return { ...state, ...INITIAL_STATE }
            }
            return { ...state }
        default:
            return state;
    }
};

export default PersonnelReducers;