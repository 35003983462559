import { api } from "../../../api/api";
import { toast } from "react-toastify";

import {
  getQuestion,
  getAdministratorList,
  getAssistantList,
  getProviderList,
  getCalendarDoctorList,
  addClient,
  getHygienistList,
  allQuestAnswer,
  addAppointmentList,
  addCalendarAppointmentList,
  addTreatmentList,
  addObservation,
  getCityData,
  getCountryData,
  getBeforeImages,
  newAppointment,
  getSubServiceList,
  addServices,
  getAfterImages,
  getRxImages,
  addFolderList,
  setColorId,
  getFormList,
  setSignutareImage,
  setStripePrice,
  allLogoImage,
  officeGetApi,
  setFormFile,
  setPersonalFile
} from "../index";

/**
 * @function appointmentApi
 * @description Used for get the appointment of user
 */
function convertTZ(date, tzString) {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
}

export const fileDeleteApi = (fileDelete) => {
  return async () => {
    var fileDataResult = await api("backend_file_delete", fileDelete, "post");
    if (
      fileDataResult.data.error === 0 &&
      fileDataResult.data.message === "OK" &&
      fileDataResult.data.result !== null
    ) {
      return fileDataResult;
    }
  };
};
export const appointmentApi = () => {
  return async (dispatch, store) => {
    var data = new FormData();
    data.append("token", store().auth.userData.token);
    data.append("version", store().auth.version);
    data.append("location_id", 0);
    data.append("timestamp", "");
    const res = await api("backend_appointment_get_list", data, "post");
    var appointment = res.data;
    if (
      appointment.error === 0 &&
      appointment.message === "OK" &&
      appointment.result !== null
    ) {
      const enableAppointment = appointment.result.filter(
        (list) => list.enabled === "1"
      );
      enableAppointment.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.ts_target) - new Date(a.ts_target);
      });
      const enableAppointmentRO = enableAppointment.map((appointment) => {
        var timeZone = store().client.officesData.timezone;
        var ts_target_main = new Date(appointment.ts_target).toUTCString();
        var ts_target = convertTZ(ts_target_main, timeZone);

        var ts_created_main = new Date(appointment.ts_created).toUTCString();
        var ts_created = convertTZ(ts_created_main, timeZone);

        var ts_modify_main = new Date(appointment.ts_modify).toUTCString();
        var ts_modify = convertTZ(ts_modify_main, timeZone);

        var ts_target_Old = new Date(appointment.ts_target_old).toUTCString();
        var ts_target_old = convertTZ(ts_target_Old, timeZone);
        return {
          ...appointment,
          ts_created,
          ts_modify,
          ts_target,
          ts_target_old,
        };
      });
      dispatch(addAppointmentList(enableAppointmentRO));
      dispatch(addCalendarAppointmentList(enableAppointmentRO))
      dispatch(newAppointment(false));
    }
  };
};
/**
 * @function deleteTreatmentApi
 * @description Used for delete the treatment
 */
export const deleteTreatmentApi = (id) => {
  return async (dispatch, store) => {
    let data = new FormData();
    data.append("token", store().auth.userData.token);
    data.append("version", store().auth.version);
    data.append("id", id);
    const treatmentList = await api("backend_treatment_delete", data, "post");
    if (treatmentList.data.error === 0 && treatmentList.data.message === "OK") {
      dispatch(treatmentApi());
      toast.success("Treatment Deleted Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("Treatment Deleted Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
};

export const imageAddApi = (formData) => {
  return async () => {
    const imageAddResult = await api("backend_image_add", formData, "post");
    if (
      imageAddResult.data.error === 0 &&
      imageAddResult.data.message === "OK" &&
      imageAddResult.data.result !== null
    ) {
      return imageAddResult.data;
    }
  };
};

/**
 * @function editTreatmentApi
 * @description Used for Edit the treatment
 */
export const editTreatmentApi = (id, name, service_id) => {
  return async (dispatch, store) => {
    let data = new FormData();
    data.append("token", store().auth.userData.token);
    data.append("version", store().auth.version);
    data.append("id", id);
    data.append("name", name);
    data.append("service_id", service_id);
    data.append("checked", 0);
    const editTreatmentResult = await api(
      "backend_treatment_set",
      data,
      "post"
    );
    if (
      editTreatmentResult.data.error === 0 &&
      editTreatmentResult.data.message === "OK"
    ) {
      dispatch(treatmentApi());
      toast.success("Treatment Edited Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("Treatment Edited Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
};

const hexToRgbA = (hex) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(", ") + ", 1)"
    );
  }
  throw new Error("Bad Hex");
};
/**
 * @function userApi
 * @description Used for get all type of users
 */
export const userApi = () => {
  return async (dispatch, store) => {
    let formData = new FormData();
    formData.append("version", store().auth.version);
    const res = await api("backend_color_get_list", formData, "post");
    const backendColorGetListResult = res.data;
    const colorId = [];
    if (
      backendColorGetListResult.error === 0 &&
      backendColorGetListResult.message === "OK" &&
      backendColorGetListResult.result !== null
    ) {
      backendColorGetListResult.result.forEach((color) => {
        // colorId[color.id] = hexToRgbA(`#${color.rgb}`);
        colorId[color.id] = `#${color.rgb}`;
      });
      dispatch(setColorId(colorId));
    }
    const data = new FormData();
    data.append("token", store().auth.userData.token);
    data.append("version", store().auth.version);
    data.append("type", store().auth.userData.type);
    data.append("data", 1);
    data.append("timestamp", "");
    const apiRes = await api("backend_user_get_list", data, "post");
    const parsedata = apiRes.data;
    var administrator = [],
      assistant = [],
      provider = [],
      client = [],
      hygienist = [];
    if (
      parsedata.error === 0 &&
      parsedata.message === "OK" &&
      parsedata.result !== null
    ) {
      for (var i = 0; i < parsedata.result.length; i++) {
        switch (parsedata.result[i].type) {
          case "1":
            administrator.push(parsedata.result[i]);
            break;
          case "2":
            assistant.push(parsedata.result[i]);
            break;
          case "3":
            provider.push(parsedata.result[i]);
            break;
          case "4":
            client.push(parsedata.result[i]);
            break;
          case "5":
            hygienist.push(parsedata.result[i]);
            break;
          default:
            break;
        }
      }
    }
    await dispatch(getAdministratorList(administrator));
    await dispatch(getAssistantList(assistant));
    await dispatch(getProviderList(provider));
    await dispatch(getCalendarDoctorList(provider));
    await dispatch(clientApi(addClient({ clientDetailsdata: client })));
    await dispatch(getHygienistList(hygienist));
  };
};

/**
 * @function questionApi
 * @description Used fior the get all questions
 */
export const questionApi = () => {
  return async (dispatch, store) => {
    const qdata = new FormData();
    qdata.append("token", store().auth.userData.token);
    qdata.append("user_id", "");
    qdata.append("version", store().auth.version);
    const questionData = await api("backend_question_get", qdata, "post");

    if (
      questionData.data.error === 0 &&
      questionData.data.message === "OK" &&
      questionData.data.result !== null
    ) {
      await dispatch(getQuestion({ questionData: questionData.data.result }));
    }
  };
};

/**
 * @function answerApi
 * @description Used for get the answers of user
 */
export const answerApi = (params = {}) => {
  return async (dispatch, store) => {
    const adata = new FormData();
    adata.append("token", store().auth.userData.token);
    adata.append("user_id", params.client_id || "");
    adata.append("timestamp", "");
    adata.append("version", store().auth.version);
    const res = await api("backend_question_get_answers", adata, "post");
    const answerData = res.data;
    if (
      answerData.error === 0 &&
      answerData.message === "OK" &&
      answerData.result !== null
    ) {
      dispatch(allQuestAnswer(answerData.result));
    }
  };
};

export const getStripePrice = () => {
  return async (dispatch, store) => {
    var data = new FormData();
    data.append("token", store().auth.userData.token);
    data.append("version", store().auth.version);
    const stripeResult = await api(
      "backend_office_subscription_get",
      data,
      "post"
    );
    if (
      stripeResult.data.error === 0 &&
      stripeResult.data.message === "OK" &&
      stripeResult.data.result !== null
    ) {
      dispatch(setStripePrice(stripeResult.data.result));
    }
  };
};

/**
 * @function redCrossApi
 * @description Used for set the red(+) button on patient list
 */
export const redCrossApi = () => {
  return async (dispatch, store) => {
    var clientdata = store().client.clientDetails;
    if (clientdata.length > 0) {
      const email_and_sms = store().client.appoinmentList.filter((value) => {
        if (value.notify_email == 1 || value.notify_sms == 1) return true;
      });
      for (var i = 0; i < clientdata.length; i++) {
        var clientId = clientdata[i].id;
        if (
          store().client.allClientAnswear &&
          store().client.allClientAnswear[clientId]
        ) {
          for (
            var j = 0;
            j < store().client.allClientAnswear[clientId].length;
            j++
          ) {
            var answear_data = store().client.allClientAnswear[clientId];
            if (answear_data[j].user_answer == 1) {
              for (var k = 0; k < store().client.questionData.length; k++) {
                if (store().client.questionData[k].alert == 1) {
                  clientdata[i]["redAlert"] = 1;
                  break;
                }
              }
            }
          }
        }

        for (var l = 0; l < email_and_sms.length; l++) {
          if (clientdata[i].id == email_and_sms[l].client_id) {
            if (email_and_sms[l].notify_email == 1) {
              clientdata[i]["notify_email"] = 1;
            }
            if (email_and_sms[l].notify_sms == 1) {
              clientdata[i]["notify_sms"] = 1;
            }
          }
        }
      }

      var clientDetailsdata = clientdata;
      await dispatch(addClient({ clientDetailsdata }));
    }

  };
};

/**
 * @function treatmentApi
 * @description Used for get the treatment list
 */
export const treatmentApi = (params = {}) => {
  return async (dispatch, store) => {
    const data = new FormData();
    data.append("client_id", params.client_id || "");
    data.append("appointment_id", "");
    data.append("timestamp", "");
    data.append("token", store().auth.userData.token);
    data.append("version", store().auth.version);
    const treatmentList = await api("backend_treatment_get_list", data, "post");
    if (
      treatmentList.data.error === 0 &&
      treatmentList.data.message === "OK" &&
      treatmentList.data.result !== null
    ) {
      if (treatmentList.data.result) {
        dispatch(addTreatmentList({ treatmentList: treatmentList.data.result }));
      }
    } else {
      dispatch(addTreatmentList({ treatmentList: [] }));
    }
  };
};

/**
 * @function observationApi
 * @description Used for get the observation list
 */
export const observationApi = (params = {}) => {
  return async (dispatch, store) => {
    var data = new FormData();
    data.append("token", store().auth.userData.token);
    data.append("client_id", params.client_id || "");
    data.append("private", "");
    data.append("timestamp", "");
    data.append("version", store().auth.version);
    var observaction_api_data = await api(
      "backend_note_get_list",
      data,
      "post"
    );
    if (
      observaction_api_data.data.error === 0 &&
      observaction_api_data.data.message === "OK" &&
      observaction_api_data.data.result !== null
    ) {
      dispatch(
        addObservation({ observationList: observaction_api_data.data.result })
      );
    }
  };
};

/**
 * @function cityApi
 * @description Used for get the city list
 */
export const cityApi = () => {
  return async (dispatch, store) => {
    let data = new FormData();
    data.append("timestamp", "");
    data.append("version", store().auth.version);
    const cityData = await api("backend_city_get_list", data, "post");
    if (
      cityData.data.error === 0 &&
      cityData.data.message === "OK" &&
      cityData.data.result !== null
    ) {
      dispatch(getCityData({ cityData: cityData.data.result }));
    }
  };
};

/**
 * @function countryApi
 * @description Used for get the country list
 */
export const countryApi = () => {
  return async (dispatch, store) => {
    let data = new FormData();
    data.append("timestamp", "");
    data.append("version", store().auth.version);
    const countryData = await api("backend_country_get_list", data, "post");
    if (
      countryData.data.error === 0 &&
      countryData.data.message === "OK" &&
      countryData.data.result !== null
    ) {
      dispatch(getCountryData({ countryData: countryData.data.result }));
    }
  };
};
/**
 * @function questionSetApi
 * @description Used for set the questions
 */
export const questionSetApi = (formAnswer) => {
  return async (dispatch, store) => {
    let data = new FormData();
    data.append("token", store().auth.userData.token);
    data.append("user_id", store().client.currentClient.id);
    data.append("answer", JSON.stringify(formAnswer));
    data.append("version", store().auth.version);

    const questionSetResult = await api("backend_question_set", data, "post");
    if (
      questionSetResult.data.error === 0 &&
      questionSetResult.data.message === "OK" &&
      questionSetResult.data.result !== null
    ) {
    }
  };
};
export const cityGetApi = (name) => {
  return async () => {
    let cityGetData = new FormData();
    cityGetData.append("country_id", "176");
    cityGetData.append("name", name);
    cityGetData.append("version", store().auth.version);

    const cityGetResult = await api("backend_city_get", cityGetData, "post");
    if (
      cityGetResult.data.error === 0 &&
      cityGetResult.data.message === "OK" &&
      cityGetResult.data.result !== null
    ) {
      return cityGetResult.data.result.id;
    } else {
    }
  };
};

export const fileAddApi = (fileAdd) => {
  return async () => {
    var fileDataResult = await api("backend_file_add", fileAdd, "post");
    if (
      fileDataResult.data.error === 0 &&
      fileDataResult.data.message === "OK" &&
      fileDataResult.data.result !== null
    ) {
      return fileDataResult.data;
    }
  };
};

export const fileEditApi = (fileEdit) => {
  return async () => {
    var fileDataResult = await api("backend_file_set", fileEdit, "post");
    if (
      fileDataResult.data.error === 0 &&
      fileDataResult.data.message === "OK" &&
      fileDataResult.data.result !== null
    ) {
      return fileDataResult.data;
    }
  };
};

/**
 * @function beforeImageApi
 * @description Used for get the before image list
 */
export const beforeImageApi = (params = {}) => {
  return async (dispatch, store) => {
    const imageGetList = new FormData();
    imageGetList.append("version", store().auth.version);
    imageGetList.append("token", store().auth.userData.token);
    imageGetList.append("category_id", 2);
    imageGetList.append("client_id", params.client_id || "");
    imageGetList.append("appointment_id", "");
    imageGetList.append("timestamp", "");
    const imageGetListResult = await api(
      "backend_image_get_list",
      imageGetList,
      "post"
    );
    if (
      imageGetListResult.data.error === 0 &&
      imageGetListResult.data.message === "OK" &&
      imageGetListResult.data.result !== null
    ) {
      dispatch(getBeforeImages(imageGetListResult.data.result));
    }
  };
};

export const getFormFileApi = (params = {}) => {
  return async (dispatch, store) => {
    const fileGetList = new FormData();
    fileGetList.append("version", store().auth.version);
    fileGetList.append("token", store().auth.userData.token);
    fileGetList.append("client_id", params.client_id || "");
    fileGetList.append("appointment_id", "");
    fileGetList.append("name", "Forms");
    fileGetList.append("type", "12");
    fileGetList.append("timestamp", "");
    const fileGetListResult = await api(
      "backend_file_get_list",
      fileGetList,
      "post"
    );
    console.log(fileGetListResult, 'this is file get list result')
    if (
      fileGetListResult.data.error === 0 &&
      fileGetListResult.data.message === "OK" &&
      fileGetListResult.data.result !== null
    ) {
      dispatch(setFormFile(fileGetListResult.data.result));
    }
  };
};

export const getPersonalFileApi = (params = {}) => {
  return async (dispatch, store) => {
    const fileGetList = new FormData();
    fileGetList.append("version", store().auth.version);
    fileGetList.append("token", store().auth.userData.token);
    fileGetList.append("client_id", params.client_id || "");
    fileGetList.append("appointment_id", "");
    fileGetList.append("name", "Personal Files");
    fileGetList.append("type", "11");
    fileGetList.append("timestamp", "");
    const fileGetListResult = await api(
      "backend_file_get_list",
      fileGetList,
      "post"
    );
    if (
      fileGetListResult.data.error === 0 &&
      fileGetListResult.data.message === "OK" &&
      fileGetListResult.data.result !== null
    ) {
      dispatch(setPersonalFile(fileGetListResult.data.result));
    }
  };
};

/**
 * @function AfterImageApi
 * @description Used for get the after image list
 */
export const AfterImageApi = (params = {}) => {
  return async (dispatch, store) => {
    const imageGetList = new FormData();
    imageGetList.append("version", store().auth.version);
    imageGetList.append("token", store().auth.userData.token);
    imageGetList.append("category_id", 3);
    imageGetList.append("client_id", params.client_id || "");
    imageGetList.append("appointment_id", "");
    imageGetList.append("timestamp", "");
    const imageGetListResult = await api(
      "backend_image_get_list",
      imageGetList,
      "post"
    );
    if (
      imageGetListResult.data.error === 0 &&
      imageGetListResult.data.message === "OK" &&
      imageGetListResult.data.result !== null
    ) {
      dispatch(getAfterImages(imageGetListResult.data.result));
    }
  };
};

/**
 * @function RxImageApi
 * @description Used for get the rx image list
 */
export const RxImageApi = (params = {}) => {
  return async (dispatch, store) => {
    const imageGetList = new FormData();
    imageGetList.append("version", store().auth.version);
    imageGetList.append("token", store().auth.userData.token);
    imageGetList.append("category_id", 1);
    imageGetList.append("client_id", params.client_id || "");
    imageGetList.append("appointment_id", "");
    imageGetList.append("timestamp", "");
    const imageGetListResult = await api(
      "backend_image_get_list",
      imageGetList,
      "post"
    );
    if (
      imageGetListResult.data.error === 0 &&
      imageGetListResult.data.message === "OK" &&
      imageGetListResult.data.result !== null
    ) {
      dispatch(getRxImages(imageGetListResult.data.result));
    }
  };
};

/**
 * @function serviceApi
 * @description Used for get the service list
 */
export const serviceApi = () => {
  return async (dispatch, store) => {
    var data = new FormData();
    data.append("version", store().auth.version);
    data.append("token", store().auth.userData.token);
    data.append("parent_id", store().auth.userData.id);
    data.append("timestamp", "");
    var res = await api("backend_service_get_list", data, "post");
    var parsedata = res.data;
    if (
      parsedata.error === 0 &&
      parsedata.message === "OK" &&
      parsedata.result !== null
    ) {
      const servicesList = parsedata.result.filter(
        (row) => row.parent_id === "0"
      );
      const subServicesList = parsedata.result.filter(
        (row) => row.parent_id !== "0"
      );
      dispatch(getSubServiceList(subServicesList));
      dispatch(addServices(servicesList));
    }
  };
};

/**
 * @function deleteAppointmentApi
 * @description Used for delete the appointment api
 */
export const deleteAppointmentApi = (id) => {
  return async (dispatch, store) => {
    let data = new FormData();
    data.append("token", store().auth.userData.token);
    data.append("id", id);
    data.append("version", store().auth.version);
    const parsedata = await api("backend_appointment_delete", data, "post");
    if (
      parsedata.data.error === 0 &&
      parsedata.data.message === "OK" &&
      parsedata.data.result !== null
    ) {
      return parsedata;
    }
  };
};

export const fileGetListApi = (params = {}) => {
  return async (dispatch, store) => {
    const fileGetList = new FormData();
    fileGetList.append("version", store().auth.version);
    fileGetList.append("token", store().auth.userData.token);
    fileGetList.append("office_id", "");
    fileGetList.append("provider_id", "");
    fileGetList.append("client_id", params.client_id || "");
    fileGetList.append("timestamp", "");
    fileGetList.append("type", "");
    const fileGetListResult = await api(
      "backend_file_get_list",
      fileGetList,
      "post"
    );
    if (
      fileGetListResult.data.error === 0 &&
      fileGetListResult.data.message === "OK" &&
      fileGetListResult.data.result !== null
    ) {
      const result = fileGetListResult.data.result.sort((a, b) => {
        if (a.timestamp < b.timestamp) {
          return 1;
        } else if (a.timestamp > b.timestamp) {
          return -1;
        }
        return 0;
      });

      const fileHtml = fileGetListResult.data.result.filter((file) => {
        if (file.type == 8 && file.enabled == 1) {
          return true;
        } else {
          return false;
        }
      });
      dispatch(addFolderList(result));
      dispatch(getFormList(fileHtml));
      return fileGetListResult;
    }
  };
};
/**
 * @function getSignatureImageList
 * @description Used for get the signature image list
 */
export const getSignatureImageList = () => {
  return async (dispatch, store) => {
    const imageGetList = new FormData();
    imageGetList.append("version", store().auth.version);
    imageGetList.append("token", store().auth.userData.token);
    imageGetList.append("category_id", 4);
    imageGetList.append(params.client_id || "");
    imageGetList.append("appointment_id", "");
    imageGetList.append("timestamp", "");
    const imageGetListResult = await api(
      "backend_image_get_list",
      imageGetList,
      "post"
    );
    if (
      imageGetListResult.data.error === 0 &&
      imageGetListResult.data.message === "OK" &&
      imageGetListResult.data.result !== null
    ) {
      dispatch(setSignutareImage(imageGetListResult.data.result));
    }
  };
};

/**
 * @function getSignatureImageList
 * @description Used for get the signature image list
 */
export const getLogoImageList = (params = {}) => {
  return async (dispatch, store) => {
    const imageGetList = new FormData();
    imageGetList.append("version", store().auth.version);
    imageGetList.append("token", store().auth.userData.token);
    imageGetList.append("category_id", 5);
    imageGetList.append("client_id", params.client_id || "");
    imageGetList.append("appointment_id", "");
    imageGetList.append("timestamp", "");
    const imageGetListResult = await api(
      "backend_image_get_list",
      imageGetList,
      "post"
    );
    if (
      imageGetListResult.data.error === 0 &&
      imageGetListResult.data.message === "OK" &&
      imageGetListResult.data.result !== null
    ) {
      await dispatch(allLogoImage(imageGetListResult.data.result));
    }
  };
};

/**
 * @function clientApi
 * @description Used for get client
 */
export const clientApi = () => {
  return async (dispatch, store) => {
    const data = new FormData();
    data.append("token", store().auth.userData.token);
    data.append("type", 4);
    data.append("data", 1);
    data.append("timestamp", "");
    data.append("version", store().auth.version);
    const res = await api("backend_user_get_list", data, "post");
    const parseData = res.data;
    if (
      parseData.error === 0 &&
      parseData.message === "OK" &&
      parseData.result !== null
    ) {
      await dispatch(addClient({ clientDetailsdata: parseData.result }));
    } else {
      await dispatch(addClient({ clientDetailsdata: [] }));
    }
  };
};

/**
 * @function administratorApi
 * @description Used for get administrator
 */
export const administratorApi = (value) => {
  return async (dispatch, store) => {
    const data = new FormData();
    data.append("token", store().auth.userData.token);
    data.append("type", 1);
    data.append("version", store().auth.version);
    data.append("data", 1);
    data.append("timestamp", "");
    let res = await api("backend_user_get_list", data, "post");
    const parseData = res.data;
    if (
      parseData.error === 0 &&
      parseData.message === "OK" &&
      parseData.result !== null
    ) {
      dispatch(getAdministratorList(parseData.result));
    }
  };
};

/**
 * @function assistantApi
 * @description Used for get assistant
 */
export const assistantApi = (value) => {
  return async (dispatch, store) => {
    const data = new FormData();
    data.append("token", store().auth.userData.token);
    data.append("type", 2);
    data.append("version", store().auth.version);
    data.append("data", 1);
    data.append("timestamp", "");
    let res = await api("backend_user_get_list", data, "post");
    const parseData = res.data;
    if (
      parseData.error === 0 &&
      parseData.message === "OK" &&
      parseData.result !== null
    ) {
      dispatch(getAssistantList(parseData.result));
    }
  };
};

/**
 * @function hygienistApi
 * @description Used for get hygienist
 */
export const hygienistApi = () => {
  return async (dispatch, store) => {
    const data = new FormData();
    data.append("token", store().auth.userData.token);
    data.append("type", 5);
    data.append("version", store().auth.version);
    data.append("data", 1);
    data.append("timestamp", "");
    let res = await api("backend_user_get_list", data, "post");
    const parseData = res.data;
    if (
      parseData.error === 0 &&
      parseData.message === "OK" &&
      parseData.result !== null
    ) {
      dispatch(getHygienistList(parseData.result));
    }
  };
};

/**
 * @function providerApi
 * @description Used for get provider
 */
export const providerApi = (value) => {
  return async (dispatch, store) => {
    let data = new FormData();
    data.append("version", store().auth.version);
    let res = await api("backend_color_get_list", data, "post");
    const backendColorGetListResult = res.data;
    const colorId = [];
    if (
      backendColorGetListResult.error === 0 &&
      backendColorGetListResult.message === "OK" &&
      backendColorGetListResult.result !== null
    ) {
      backendColorGetListResult.result.forEach((color) => {
        // colorId[color.id] = hexToRgbA(`#${color.rgb}`);
        colorId[color.id] = `#${color.rgb}`;
      });
      dispatch(setColorId(colorId));
    }
    const formData = new FormData();
    formData.append("token", store().auth.userData.token);
    formData.append("type", 3);
    formData.append("version", store().auth.version);
    formData.append("data", 1);
    formData.append("timestamp", "");
    let response = await api("backend_user_get_list", formData, "post");
    const parseData = response.data;
    if (
      parseData.error === 0 &&
      parseData.message === "OK" &&
      parseData.result !== null
    ) {
      dispatch(getProviderList(parseData.result));
    }
  };
};

export const officeSetApi = (value) => {
  return async (dispatch, store) => {
    const officeGetResult = await api("backend_office_set", value, "post");
    if (
      officeGetResult.data.error === 0 &&
      officeGetResult.data.message === "OK" &&
      officeGetResult.data.result !== null
    ) {
      await dispatch(officeGetApi());
    }
  };
};

/**
 * @function editPatientApi
 * @description Used for get client
 */
export const editPatientApi = (formData) => {
  return async (dispatch, store) => {
    api('backend_user_set_data', formData, "post").then((res) => {
    })
  };
};