import {
  SHOW_MODAL,
  SHOW_LOGIN,
  ADD_APPOINTMENT_MODAL,
  CURRENT_EVENT_VALUE,
  UPDATE_CALENDER_FLAG,
  UPDATE_CALENDER_EVENT,
  COMPARE_IMAGE_MODAL,
  SET_UPLOAD_MODAL,
  SET_ADD_ONE_APPOINTMENT,
  DUBLE_CLICK_DATE,
  SHOW_CALENDAR_MODAL,
  SET_CALENDAR_MODAL_TYPE,
  ADD_APPOINTMENT_MOD,
  SELECTED_ANYWHERE_FLAG
} from "../../storeConfig/types";

/**
 * @variable INITIAL_STATE
 * @description initial value of modal state
 */
const INITIAL_STATE = {
  showmodal: false,
  showAddAppointment: false,
  showMod: false,
  currentEventValue: {},
  calenderFlag: false,
  updateEvent: false,
  compareImageModal: false,
  uploadModal: false,
  addOneAppointment: {},
  dblClickDate: "",
  calendarModal: false,
  type: "add",
  selectedAnyWhereFlag : false
};

/**
 * @function ModalReducer
 * @description update the store according to action
 */
const ModalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_CALENDAR_MODAL:
      return { ...state, calendarModal: action.payload.value };
    case SELECTED_ANYWHERE_FLAG : 
      return { ...state, selectedAnyWhereFlag: action.payload.value };
    case SET_CALENDAR_MODAL_TYPE:
      return { ...state, type: action.payload.value };
    case SHOW_MODAL:
      return { ...state, showmodal: action.payload.value };
    case ADD_APPOINTMENT_MODAL:
      return { ...state, showAddAppointment: action.payload.value };
    case ADD_APPOINTMENT_MOD:
      return { ...state, showMod: action.payload.value };
    case CURRENT_EVENT_VALUE:
      return { ...state, currentEventValue: action.payload.value };
    case UPDATE_CALENDER_FLAG:
      return { ...state, calenderFlag: action.payload.data };
    case UPDATE_CALENDER_EVENT:
      return { ...state, updateEvent: action.payload.value };
    case COMPARE_IMAGE_MODAL:
      return { ...state, compareImageModal: action.payload.value };
    case SET_UPLOAD_MODAL:
      return { ...state, uploadModal: action.payload.value };
    case SET_ADD_ONE_APPOINTMENT:
      return { ...state, addOneAppointment: action.payload.value };
    case DUBLE_CLICK_DATE:
      return { ...state, dblClickDate: action.payload.value };
    case SHOW_LOGIN:
      if (!action.payload.showLogindata) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
    default:
      return state;
  }
};

export default ModalReducer;
