import {
    SHOW_LOGIN,
    SET_CURRENT_FOLDER,
    SHOW_FORM_MODEL,
    SET_CURRENT_FORM_TYPE,
    SET_SIGNATURE_MODAL,
    SAVE_SOURCES_DIV,
    PDF_MODAL_VIEW,
    SET_FOLDER_LIST,
    SET_FILE_PATH,
    SET_SELECT_FILE_DATA,
    SET_FORM_TYPE,
    PREVENT_STEP,
    SAVE_FORM_PDF,
    SET_NEW_FORM_SHOW,
    USER_FORMS_DATA,
    DELETE_CONFIRM_POPUP,
    FORM_HTML_LIST,
    EDIT_FORM,
    EDIT_FORM_DATA,
    SET_TEMPLATES_FORM,
    SET_FORMS_FILE,
    SET_PERSONNEL_FILE
} from "../../storeConfig/types";

/**
 * @variable INITIAL_STATE
 * @description initial value of formFolder state
 */
const INITIAL_STATE = {
    folders: {
        '/': [
            {
                name: 'Forms',
                type: 'folder',
                en: 'Forms',
                ro: 'Formulare'
            },
            {
                name: 'Templates',
                type: 'folder',
                en: 'Templates',
                ro: 'Tipare'
            },
            {
                name: 'Personal files',
                type: 'folder',
                en: 'Personal files',
                ro: 'Fise personale'
            }
        ],
        '/Forms': [
            {
                name: '..',
                type: 'folder',
                ro: '..'
            }
        ],
        '/Templates': [
            {
                name: '..',
                type: 'folder',
                ro: '..'
            }
        ],
        '/Details': [
            {
                name: '..',
                type: 'folder',
                ro: '..'
            }
        ]
    },
    preventStepFlag: false,
    currentFolder: '/',
    formModel: false,
    currentFormType: false,
    formType: 'icpf',
    signutareModal: false,
    sources: '',
    pdfModal: false,
    formList: [],
    getHtmlForm: [],
    selectFileData: {},
    saveFormPdfFlag: false,
    showNewAddForm: false,
    deleteConfirmPopup: false,
    editForm: false,
    editFormData: {},
    templatesEditFolder: false,
    userForms: [
        {
            name: "Informed consent permission form",
            type: 'icpf'
        }, {
            name: "Consent for oral surgery",
            type: 'cfos'
        }, {
            name: "Informed consent prosthetics",
            type: 'icp'
        }
    ],
    formsFile: [],
    personalFiles: []
}

/**
 * @function FormFolderReducer
 * @description update the store according to action
 */
const FormFolderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PERSONNEL_FILE:
            return { ...state, personalFiles: action.payload.data }
        case SET_FORMS_FILE:
            return { ...state, formsFile: action.payload.data }
        case SET_TEMPLATES_FORM:
            return { ...state, templatesEditFolder: action.payload.value }
        case EDIT_FORM:
            return { ...state, editForm: action.payload.value }
        case EDIT_FORM_DATA:
            return { ...state, editFormData: action.payload.value }
        case DELETE_CONFIRM_POPUP:
            return { ...state, deleteConfirmPopup: action.payload.data }
        case USER_FORMS_DATA:
            return { ...state, userForms: action.payload.data }
        case SET_NEW_FORM_SHOW:
            return { ...state, showNewAddForm: action.payload.data }
        case SET_CURRENT_FOLDER:
            return { ...state, currentFolder: action.payload.data }
        case SHOW_FORM_MODEL:
            return { ...state, formModel: action.payload.data }
        case SET_CURRENT_FORM_TYPE:
            return { ...state, currentFormType: action.payload.data }
        case SET_FORM_TYPE:
            return { ...state, formType: action.payload.data, currentFormType: true, formModel: false }
        case SET_SIGNATURE_MODAL:
            return { ...state, signutareModal: action.payload.value }
        case SAVE_SOURCES_DIV:
            return { ...state, sources: action.payload.value }
        case PDF_MODAL_VIEW:
            return { ...state, pdfModal: action.payload.value }
        case SET_FOLDER_LIST:
            return { ...state, formList: action.payload.value }
        case FORM_HTML_LIST:
            return { ...state, getHtmlForm: action.payload.value }
        case SET_FILE_PATH:
            var folder = {};
            folder["/"] = [
                {
                    name: 'Forms',
                    type: 'folder',
                    en: 'Forms',
                    ro: 'Formulare'
                },
                {
                    name: 'Templates',
                    type: 'folder',
                    en: 'Templates',
                    ro: 'Tipare'
                },
                {
                    name: 'Personal files',
                    type: 'folder',
                    en: 'Personal files',
                    ro: 'Fise personale'
                }
            ];
            folder['/Forms'] = action.payload.form;
            folder['/Templates'] = action.payload.templetes;
            folder['/Personal files'] = action.payload.details;
            return { ...state, folders: folder }
        case SET_SELECT_FILE_DATA:
            return { ...state, selectFileData: action.payload.value }
        case PREVENT_STEP:
            return { ...state, preventStepFlag: action.payload.data }
        case SAVE_FORM_PDF:
            return { ...state, saveFormPdfFlag: action.payload.data }
        case SHOW_LOGIN:
            if (!action.payload.showLogindata) {
                return { ...state, ...INITIAL_STATE }
            }
            return { ...state }
        default:
            return state;
    }
};

export default FormFolderReducer;