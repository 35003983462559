import { SET_XRAY_CURRENT_FOLDER, GET_BEFORE_IMAGES, GET_AFTER_IMAGES, GET_RX_IMAGES } from '../../storeConfig/types';

/**
 * @function setXRayCurrentFolder
 * @description Used for get the current folder
 */
export const setXRayCurrentFolder = value => {
    return {
        type: SET_XRAY_CURRENT_FOLDER,
        payload: { data: value }
    }
}

/**
 * @function getBeforeImages
 * @description Used for get before image list
 */
export const getBeforeImages = value => {
    return {
        type: GET_BEFORE_IMAGES,
        payload: { data: value }
    }
}

/**
 * @function getAfterImages
 * @description Used for get after image list
 */
export const getAfterImages = value => {
    return {
        type: GET_AFTER_IMAGES,
        payload: { data: value }
    }
}

/**
 * @function getRxImages
 * @description Used for get rx image list
 */
export const getRxImages = value => {
    return {
        type: GET_RX_IMAGES,
        payload: { data: value }
    }
}