/** @format */

// ** Redux Imports
import { combineReducers } from "redux";
// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import invoice from "@src/views/apps/invoice/store/reducer";
import todo from "../../views/apps/calendar/store/reducer";
import calendar from "@src/views/apps/calendar/store/reducer";
import StatisticsReducer from "./statistic/statisticReducer";
import ClientReducer from "./client/clientReducer";
import PatientReducer from "./patient/index";
import ModalReducer from "./modal/modalReducer";
import PersonnelReducers from "./personnel/personnelReducer";
import AppointmentReducer from "./appointment/appointmentReducer";
import FormFolder from "./formFolder/formFolderReducer";
import Services from "./service/serviceReducer";
import SettingReducers from "./setting/settingReducer";
import XRay from "./xRay/xRayReducer";
import LoaderReducer from "./loader/loaderReducer";

// import dataTables from "@src/views/tables/data-tables/store/reducer";

const appReducer = combineReducers({
  auth,
  navbar,
  layout,
  invoice,
  calendar,
  todo,
  patient: PatientReducer,
  statistics: StatisticsReducer,
  client: ClientReducer,
  modal: ModalReducer,
  personnel: PersonnelReducers,
  appointment: AppointmentReducer,
  formFolder: FormFolder,
  services: Services,
  setting: SettingReducers,
  xray: XRay,
  loader: LoaderReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export default rootReducer;
