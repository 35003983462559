import {
    GET_ADMINISTRATOR_LIST,
    GET_ASSISTANT_LIST,
    GET_PROVIDER_LIST,
    GET_HYGIENIST_LIST,
    GET_CURRENT_PERSON,
    EDIT_PERSONNEL_FLAG,
    CHANGE_PERSONNEL_LIST_FLAG,
    SET_PERSONNEL_LOADING_FLAG,
    SET_PERSONNEL_DEFAULT_STATE,
    CHANGE_TYPE,
    SET_SEARCH_ADMINISTRATOR_LIST,
    SET_SEARCH_ASSISTANT_LIST,
    SET_SEARCH_PROVIDER_LIST,
    SET_SEARCH_HYGIENIST_LIST,
    SET_USER_LIST,
    SET_COLOR_ID,
    SET_NEW_FORM_TYPE,
    CHANGE_CURRENT_CLIENT_FLAG
} from '../../storeConfig/types';

/**
 * @function getAdministratorList
 * @description update the Administrator data in store
 */

export const getAdministratorList = value => {
    return {
        type: GET_ADMINISTRATOR_LIST,
        payload: { data: value }
    }
}

/**
 * @function getAssistantList
 * @description update the Assistant data in store
 */

export const getAssistantList = value => {
    return {
        type: GET_ASSISTANT_LIST,
        payload: { data: value }
    }
}

/**
 * @function getProviderList
 * @description update the Provider data in store
 */

export const getProviderList = value => {
    return (dispatch, store) => {
        dispatch({
            type: GET_PROVIDER_LIST,
            payload: { data: value }
        });
        if (value.length === 0) {
            dispatch(editPersonnelFlag(false));
        } else {
            if(store().personnel.currentClientFlag) {
                dispatch(changeCurrentClientFlag(false));
            } else {                
                dispatch(getCurrentPerson(value[0]));
            }
        }
    }
}

/**
 * @function getHygienistList
 * @description update the Hygienist data in store
 */

export const getHygienistList = value => {
    return {
        type: GET_HYGIENIST_LIST,
        payload: { data: value }
    }
}

/**
 * @function getCurrentPerson
 * @description select the cuurrent personnel data
 */

export const getCurrentPerson = value => {
    return {
        type: GET_CURRENT_PERSON,
        payload: { data: value }
    }
}

/**
 * @function editPersonnelFlag
 * @description check the form type is edit or add
 */

export const editPersonnelFlag = value => {
    return {
        type: EDIT_PERSONNEL_FLAG,
        payload: { data: value }
    }
}

/**
 * @function changePersonnelListFlag
 * @description check the personnel list need to update or not
 */

export const changePersonnelListFlag = value => {
    return {
        type: CHANGE_PERSONNEL_LIST_FLAG,
        payload: { data: value }
    }
}

/**
 * @function setPersonnelLoadingFlag
 * @description check the loading shows or not
 */

export const setPersonnelLoadingFlag = value => {
    return {
        type: SET_PERSONNEL_LOADING_FLAG,
        payload: { data: value }
    }
}

/**
 * @function setPersonnelDefaultState
 * @description when route change at that time, some flag is reset
 */

export const setPersonnelDefaultState = () => {
    return {
        type: SET_PERSONNEL_DEFAULT_STATE
    }
}

/**
 * @function changeType
 * @description change the type object in state
 */

export const changeType = value => {
    return {
        type: CHANGE_TYPE,
        payload: { data: value }
    }
}

/**
 * @function setSearchAdministratorList
 * @description set the administrator list according to search value
 */
export const setSearchAdministratorList = value => {
    return {
        type: SET_SEARCH_ADMINISTRATOR_LIST,
        payload: { data: value }
    }
}

/**
 * @function setSearchAdministratorList
 * @description set the assistant list according to search value
 */
export const setSearchAssistantList = value => {
    return {
        type: SET_SEARCH_ASSISTANT_LIST,
        payload: { data: value }
    }
}

/**
 * @function setSearchAdministratorList
 * @description set the provider list according to search value
 */
export const setSearchProviderList = value => {
    return {
        type: SET_SEARCH_PROVIDER_LIST,
        payload: { data: value }
    }
}

/**
 * @function setSearchAdministratorList
 * @description set the hygienist list according to search value
 */
export const setSearchHygienistList = value => {
    return {
        type: SET_SEARCH_HYGIENIST_LIST,
        payload: { data: value }
    }
}

/**
 * @function setUserList
 * @description set the User list 
 */
export const setUserList = value => {
    return {
        type: SET_USER_LIST,
        payload: { data: value }
    }
}

export const setColorId = value => {
    return {
        type: SET_COLOR_ID,
        payload: { data: value }
    }
}


/**
 * @function setNewFormType
 * @description get the new form type
 */
export const setNewFormType = value => {
    return {
        type: SET_NEW_FORM_TYPE,
        payload: { data: value }
    }
}

/**
 * @function changeCurrentClientFlag
 * @description get the new form type
 */
export const changeCurrentClientFlag = value => {
    return {
        type: CHANGE_CURRENT_CLIENT_FLAG,
        payload: { data: value }
    }
}