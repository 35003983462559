/** @format */

// ** React Imports
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability";
import { ToastContainer } from "react-toastify";
import { AbilityContext } from "./utility/context/Can";
import { ThemeContext } from "./utility/context/ThemeColors";
import { IntlProviderWrapper } from "./utility/context/Internationalization";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";
import ComponentSpinner from "./@core/components/spinner/Loading-spinner";
import { handleLogin, setLoader, userApi } from "./redux/actions";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** Fake Database
import "./@fake-db";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import "./assets/scss/style_new.scss";
import "./assets/scss/responsive.css";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import AddAppointment from "./views/patient/detail/AddAppointment";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

const jsx = (
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <IntlProviderWrapper>
            <LazyApp />
            <AddAppointment />
            <ToastContainer newestOnTop autoClose={2000} />
          </IntlProviderWrapper>
        </ThemeContext>
      </AbilityContext.Provider>
      <ComponentSpinner />
    </Suspense>
  </Provider>
);

let hasRendered = false;

const renderApp = async () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById("root"));
    // registerServiceWorker();
    hasRendered = true;
  }
};

// const renderLogin = () => {
//   localStorage.removeItem("dentWare");
//   renderApp();
// };

const trylogin = async () => {
  try {
    const login = await localStorage.getItem("dentWare");
    if (login) {
      const loginObject = JSON.parse(login);
      await store.dispatch(handleLogin(loginObject));
      await store.dispatch(userApi());
      store.dispatch(setLoader(false));
      renderApp();
    } else {
      renderApp();
    }
  } catch (e) {
    console.log(e);
  }
};

trylogin();

// ReactDOM.render(
//   <Provider store={store}>
//     <Suspense fallback={<Spinner />}>
//       <AbilityContext.Provider value={ability}>
//         <ThemeContext>
//           <IntlProviderWrapper>
//             <LazyApp />
//             <ToastContainer newestOnTop autoClose={2000} />
//           </IntlProviderWrapper>
//         </ThemeContext>
//       </AbilityContext.Provider>
//     </Suspense>
//   </Provider>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
