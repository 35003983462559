import {
    SET_CURRENT_FOLDER,
    SHOW_FORM_MODEL,
    SET_CURRENT_FORM_TYPE,
    SET_SIGNATURE_MODAL,
    SAVE_SOURCES_DIV,
    PDF_MODAL_VIEW,
    SET_FOLDER_LIST,
    SET_FILE_PATH,
    SET_SELECT_FILE_DATA,
    SET_FORM_TYPE,
    PREVENT_STEP,
    SAVE_FORM_PDF,
    SET_NEW_FORM_SHOW,
    DELETE_CONFIRM_POPUP,
    FORM_HTML_LIST,
    EDIT_FORM,
    EDIT_FORM_DATA,
    SET_TEMPLATES_FORM,
    SET_PERSONNEL_FILE,
    SET_FORMS_FILE
} from '../../storeConfig/types';

/**
 * @function setcurrentFolder
 * @description detect current folder
 */

export const setcurrentFolder = value => {
    return {
        type: SET_CURRENT_FOLDER,
        payload: { data: value }
    }
}

/**
 * @function showFormModel
 * @description used for display the form model or not
 */

export const showFormModel = value => {
    return {
        type: SHOW_FORM_MODEL,
        payload: { data: value }
    }
}

/**
 * @function setCurrentFormType
 * @description Use for model display or not
 */

export const setCurrentFormType = value => {
    return dispatch => {
        // dispatch({
        //     type:SHOW_FORM_MODEL,
        //     payload: { data: false }
        // })

        dispatch({
            type: SET_CURRENT_FORM_TYPE,
            payload: { data: value }
        })
    }
}

/**
 * @function setCurrentFormType
 * @description detect the current folder type
 */
export const setFormType = value => {
    return dispatch => {
        dispatch({
            type: SET_FORM_TYPE,
            payload: { data: value }
        })
    }
}

/**
 * @function setSignutareModal
 * @description set the signature flag
 */
export const setSignutareModal = value => {

    return {
        type: SET_SIGNATURE_MODAL,
        payload: { value }
    }
}

/**
 * @function saveSources
 * @description Used for save the source
 */
export const saveSources = value => {
    return {
        type: SAVE_SOURCES_DIV,
        payload: { value }
    }
}

/**
 * @function pdfModalView
 * @description Used for show the pdf view
 */
export const pdfModalView = value => {
    return {
        type: PDF_MODAL_VIEW,
        payload: { value }
    }
}

/**
 * @function addFolderList
 * @description Used for set the folder list
 */
export const addFolderList = value => {
    return {
        type: SET_FOLDER_LIST,
        payload: { value }
    }
}

export const getFormList = value => {
    return {
        type: FORM_HTML_LIST,
        payload: { value }
    }
}

/**
 * @function addFilePathName
 * @description Used for set the file path
 */
export const addFilePathName = (form, templets) => {
    var Details = [{
        name: '..',
        type: 'folder',
        ro: '..'
    }];

    var formData = [{
        name: '..',
        type: 'folder',
        ro: '..'
    }];
    form.filter((data) => {

        if (data.type && (data.type == '9' || data.type == '11') && data.type != "folder") {
            Details.push(data)
        } else if (data.type != "folder" && data.type == '12') {
            formData.push(data)
        } else {

        }
        return false;
    })


    return {
        type: SET_FILE_PATH,
        payload: { form: formData, templetes: templets, details: Details }
    }
}

/**
 * @function setSelectFileData
 * @description Used for select the current folder data
 */
export const setSelectFileData = value => {
    return {
        type: SET_SELECT_FILE_DATA,
        payload: { value }
    }
}

export const preventStep = value => {
    return {
        type: PREVENT_STEP,
        payload: { data: value }
    }
}

export const saveFormPdf = value => {
    return {
        type: SAVE_FORM_PDF,
        payload: { data: value }
    }
}

export const setShowNewAddForm = value => {
    return {
        type: SET_NEW_FORM_SHOW,
        payload: { data: value }
    }
}

export const deleteConformpopup = value => {
    return {
        type: DELETE_CONFIRM_POPUP,
        payload: { data: value }
    }
}

export const setShowEditForm = value => {
    return {
        type: EDIT_FORM,
        payload: { value }
    }
}

export const setEditFormData = value => {
    return {
        type: EDIT_FORM_DATA,
        payload: { value }
    }
}

export const setTemplates = value => {
    return {
        type: SET_TEMPLATES_FORM,
        payload: { value }
    }
}

export const setFormFile = value => {
    return {
        type: SET_FORMS_FILE,
        payload: { data: value }
    }
}

export const setPersonalFile = value => {
    return {
        type: SET_PERSONNEL_FILE,
        payload: { data: value }
    }
}