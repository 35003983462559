import { connect } from "react-redux";
import { FadeLoader } from "react-spinners";

const ComponentSpinner = ({loader}) => {
  return (
    loader &&(
    <div className="custom-loader fallback-spinner">
      <div className="loading component-loader" style={{minWidth : 'fit-content'}}>
        <FadeLoader size={30} color="#1FBFAF"/>
      </div>
    </div>)
  );
};

const mapStateToProps = (state) => {
  const { loader } = state.loader;
  return { loader };
};

export default connect(mapStateToProps, {})(ComponentSpinner);
