/** @format */

import React from "react";
import $ from "jquery";
import {
  appointmentApi,
  officeGetStatsApi,
  changeClientAppointmentFlag,
  dbleClickDate,
  clientApi,
  redCrossApi,
  addSingleAppointment,
  addSingleAppoinment,
  updateCalenderFlag,
  activeServices,
  addAppointmentList,
  updateCalenderEvent,
  addAppointmentMod,
  setLoader,
} from "../../../redux/actions";
import Flatpickr from "react-flatpickr";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  Row,
  Col,
  ModalBody,
  ModalFooter,
  ButtonToggle,
  Label,
  FormGroup,
  CustomInput,
  Input,
  Button,
} from "reactstrap";
import { ChevronRight, ChevronLeft } from "react-feather";
import { selectThemeColors } from "@utils";
import Select from "react-select";
import { api } from "../../../api/api";
import moment from "moment";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";
import "@styles/react/libs/react-select/_react-select.scss";
import "@styles/react/libs/flatpickr/flatpickr.scss";

class AddAppointment extends React.Component {
  state = {
    showTime: false,
    showTime2: false,
    time: "12:34pm",
    basic: new Date(),
    date: moment(new Date()).format("dddd,DD MMM YYYY"),
    fromTime: this.props.dblClickDate
      ? moment(this.props.dblClickDate).format("kk:mm")
      : moment().format("kk:mm"),
    toTime: this.props.dblClickDate
      ? moment(this.props.dblClickDate).add(1, "hours").format("kk:mm")
      : moment().add(1, "hours").format("kk:mm"),
    notes: "",
    notify_email: 0,
    notify_sms: 1,
    provider_id: "",
    client_id: "",
    showSearch: false,
    searchValue: "",
    selectValue: "",
    showPatientSearch: false,
    searchPatientValue: "",
    selectPatientValue: "",
    showNoteSearch: false,
    searchNoteValue: "",
    selectedServiceFlag: false,
    doctorFocus: false,
    patientFocus: false,
    treatmentFocus: false,
    validSlot: false,
    validFromTime: false,
    validToTime: false
  };

  countAppointment = (
    event,
    selectedAppointmentStartTime,
    selectedAppointmentEndTime,
    count,
    sameDoctorCount,
    doctors,
    skipId,
    flag
  ) => {
    const overlapAppointmentList = [];
    // const overrideArry = [];
    for (var i = 0; i < doctors.length; i++) {
      const appointmentStartTime = moment(doctors[i].start).format("kk:mm");
      const appointmentEndTime = moment(doctors[i].end).format("kk:mm");
      if (!skipId.includes(doctors[i].id)) {
        // console.log('id', event.id, 'doctorId', doctors[i].id, 'skipId', skipId);
        if (
          selectedAppointmentStartTime <= appointmentStartTime &&
          appointmentStartTime <= selectedAppointmentEndTime &&
          selectedAppointmentStartTime <= appointmentEndTime &&
          appointmentEndTime <= selectedAppointmentEndTime
        ) {
          // console.log('between start');
          overlapAppointmentList.push(doctors[i]);
          try {
            skipId.push(event.id);
          } catch (e) {
            console.log(e);
          }

          // console.log('skipId', skipId, 'id', event.id, 'doctorId', doctors[i].id);
          if (flag) {
            count++;
          }
          const countAppointmentResult = this.countAppointment(
            { id: doctors[i].id, provider_id: doctors[i].provider_id },
            appointmentStartTime,
            appointmentEndTime,
            0,
            0,
            doctors,
            skipId,
            true
          );
          // console.log('countAppointmentResult.count', countAppointmentResult.count);
          // console.log('countAppointmentResult.overlapAppointmentList', countAppointmentResult.overlapAppointmentList.length);
          count =
            count +
            countAppointmentResult.overlapAppointmentList.length +
            countAppointmentResult.count; // - countAppointmentResult.count
          try {
            skipId = skipId.filter((value) => value != event.id);
          } catch (e) {
            console.log(e);
          }

          if (event.provider_id == doctors[i].provider_id) {
            // console.log('Zero')
            sameDoctorCount++;
          }
          // console.log('between end');
        } else if (
          appointmentStartTime == selectedAppointmentStartTime &&
          appointmentEndTime == selectedAppointmentEndTime
        ) {
          // console.log('Zero')
          count++;
          if (event.provider_id == doctors[i].provider_id) {
            // console.log('Zero')
            sameDoctorCount++;
          }
        } else if (
          appointmentStartTime < selectedAppointmentStartTime &&
          selectedAppointmentStartTime < appointmentEndTime
        ) {
          // console.log('First', appointmentStartTime, '-', appointmentEndTime)
          count++;
          if (event.provider_id == doctors[i].provider_id) {
            // console.log('First')
            sameDoctorCount++;
          }
          // overrideArry.push({ start: appointmentStartTime, end: appointmentEndTime });
        } else if (
          appointmentStartTime < selectedAppointmentEndTime &&
          selectedAppointmentEndTime < appointmentEndTime
        ) {
          // console.log('Second', appointmentStartTime, '-', appointmentEndTime)
          count++;
          if (event.provider_id == doctors[i].provider_id) {
            // console.log('Second')
            sameDoctorCount++;
          }
          // overrideArry.push({ start: appointmentStartTime, end: appointmentEndTime });
        } else if (
          selectedAppointmentStartTime < appointmentStartTime &&
          appointmentStartTime < selectedAppointmentEndTime
        ) {
          // console.log('Third')
          count++;
          if (event.provider_id == doctors[i].provider_id) {
            // console.log('Third')
            sameDoctorCount++;
          }
        } else if (
          selectedAppointmentStartTime < appointmentEndTime &&
          appointmentEndTime < selectedAppointmentEndTime
        ) {
          // console.log('Fourth')
          count++;
          if (event.provider_id == doctors[i].provider_id) {
            // console.log('Fourth')
            sameDoctorCount++;
          }
        }
      }
    }
    return { count, sameDoctorCount, overlapAppointmentList };
  };
  componentDidMount = () => {
    this.checkFromTime(this.state.fromTime);
    this.checkToTime(this.state.toTime);
  };

  /**
   * @function setDateFunction
   * @description set date in local store
   */
  setDateFunction = (name, date) => {
    if (name == "to") {
      this.setState({
        toTime: date,
        validSlot: false,
      });
    } else {
      this.setState({
        fromTime: date,
        validSlot: false,
      });
    }
  };

  /**
   * @function nextDate
   * @description change the next date
   */
  nextDate = () => {
    var date = !this.state.date ? new Date() : new Date(this.state.date);
    date.setDate(date.getDate() + 1);
    var dayNumber = moment(date).weekday();
    // if (dayNumber == 6) {
    //     date.setDate(date.getDate() + 2);
    // }
    // if (dayNumber == 1) {
    //     date.setDate(date.getDate() + 1);
    // }
    this.setState({
      date: moment(date).format("dddd,DD MMM YYYY"),
      validSlot: false,
    });
  };

  /**
   * @function previewDate
   * @description change the preview date
   */
  previewDate = () => {
    var toDate = new Date();
    var date = new Date(this.state.date);
    date.setDate(date.getDate() - 1);
    var dayNumber = moment(date).weekday();

    if (moment(toDate).isSameOrBefore(date, "days")) {
      this.setState({
        date: moment(date).format("dddd,DD MMM YYYY"),
        validSlot: false,
      });
    }
  };

  /**
   * @function handleInputChange
   * @description set state according to field
   */
  handleInputChange(event) {
    if (
      event.target.name == "notes" ||
      event.target.name == "provider_id" ||
      event.target.name == "client_id"
    ) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    } else {
      if (event.target.checked == true) {
        this.setState({
          [event.target.name]: 1,
        });
      } else {
        this.setState({
          [event.target.name]: 0,
        });
      }
    }
  }

  /**
   * @function saveClick
   * @description save the appointment
   */
  saveClick = async () => {
    // console.log('unit = ',this.props.units);
    this.props.setLoader(true);
    // console.log(this.state);
    const doctors = this.props.appoinmentList.filter((value) =>
      moment(this.state.date).isSame(value.ts_target, "days")
    );
    doctors.sort((a, b) => new Date(a.ts_target) - new Date(b.ts_target));
    var count = 0;
    var sameDoctorCount = 0;

    // console.log(doctors);

    const selectedAppointmentStartTime = this.state.fromTime;
    const selectedAppointmentEndTime = this.state.toTime;

    const countAppointmentResult = this.countAppointment(
      { provider_id: this.state.provider_id },
      selectedAppointmentStartTime,
      selectedAppointmentEndTime,
      count,
      sameDoctorCount,
      doctors,
      [],
      false
    );

    count = countAppointmentResult.count;
    sameDoctorCount = countAppointmentResult.sameDoctorCount;

    // console.log('sameDoctorCount ', sameDoctorCount);
    if (sameDoctorCount >= 1) {
      // console.log('another doctor choose');
      this.setState({ validSlot: true });
      this.props.setLoader(false);
      return true;
    }

    // console.log('another doctor count', count);
    // console.log('unit = ',this.props.units);
    if (
      count > parseInt(this.props.units) ||
      count == parseInt(this.props.units)
    ) {
      // this.props.units

      this.setState({ validSlot: true });
      this.props.setLoader(false);
      return true;
    } else {
      var data = new FormData();
      data.append("token", this.props.token);
      data.append("provider_id", this.state.selectValue.value);
      data.append("client_id", this.state.selectPatientValue.value);
      data.append("location_id", "0");
      data.append("timestamp", moment().format("YYYY-MM-DD HH:mm:ss"));
      data.append(
        "ts_target",
        moment(`${this.state.date} ${this.state.fromTime}:00`).format(
          "YYYY-MM-DD kk:mm:ss"
        )
      );
      data.append(
        "duration",
        moment(moment(this.state.toTime, "HH:mm")).diff(
          moment(this.state.fromTime, "HH:mm"),
          "minutes"
        )
      );
      data.append("notes", this.state.notes.value);
      data.append("notify_email", this.state.notify_email);
      data.append("notify_sms", this.state.notify_sms);
      data.append("version", "5");
      // var data = {
      //   token: this.props.token,
      //   provider_id: this.state.provider_id.value,
      //   client_id: this.state.client_id.value,
      //   location_id: "0",
      //   timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
      //   // ts_target: moment(this.state.date).format("YYYY-MM-DD") + ' ' + this.state.fromTime + ':00',
      //   ts_target: moment(
      //     `${this.state.date} ${this.state.fromTime}:00`
      //   ).format("YYYY-MM-DD kk:mm:ss"),
      //   // ts_target: moment(`${this.state.date} ${this.state.fromTime}:00`).utc().format("YYYY-MM-DD kk:mm:ss"),
      //   duration: moment(moment(this.state.toTime, "HH:mm")).diff(
      //     moment(this.state.fromTime, "HH:mm"),
      //     "minutes"
      //   ),
      //   notes: this.state.notes.value,
      //   notify_email: this.state.notify_email,
      //   notify_sms: this.state.notify_sms
      // };
      var new_data = await api("backend_appointment_add", data, "post");
      if (new_data.data.message === "OK") {
        await this.props.addSingleAppointment(data);
        await this.props.changeClientAppointmentFlag(true);
        // await this.props.clientApi();
        toast.success("Programare adăugată cu succes", {
          position: toast.POSITION.TOP_RIGHT,
        });

        await this.props.officeGetStatsApi(9, 365);
        await this.props.officeGetStatsApi(10, 30);

        var appointment = await this.getAppointments();
        await this.props.redCrossApi();

        this.props.dbleClickDate("");
        this.setState({
          date: moment(new Date()).format("dddd,DD MMM YYYY"),
          fromTime: this.props.dblClickDate
            ? moment(this.props.dblClickDate).format("kk:mm")
            : moment().format("kk:mm"),
          toTime: this.props.dblClickDate
            ? moment(this.props.dblClickDate).add(1, "hours").format("kk:mm")
            : moment().add(1, "hours").format("kk:mm"),
          notes: "",
          notify_email: 0,
          notify_sms: 1,
          selectPatientValue: "",
          selectValue: "",
        });
        this.props.addAppointmentMod(false);
      } else {
        this.props.setLoader(false);
        toast.error("Programare adăugată eșuată", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      this.props.setLoader(false);
    }
  };

  /**
   * @function getAppointments
   * @description get appointment from database
   */
  getAppointments = async () => {
    await this.props.appointmentApi();
    await this.props.updateCalenderFlag(true);
    await this.props.updateCalenderEvent(true);
  };

  selectProvider = (provider) => {
    this.setState(() => ({
      provider_id: provider.id,
      selectValue: `${provider.first_name} ${provider.last_name}`,
      showSearch: false,
    }));
  };

  selectPatient = (patient) => {
    this.setState(() => ({
      client_id: patient.id,
      selectPatientValue: `${patient.first_name} ${patient.last_name}`,
      showPatientSearch: false,
    }));
  };

  selectNote = (note) => {
    this.setState(() => ({
      notes: note.name,
      showNoteSearch: false,
    }));
  };
  handleResetInputValues = () => {
    this.setState({
      date: moment(new Date()).format("dddd,DD MMM YYYY"),
      fromTime: this.props.dblClickDate
        ? moment(this.props.dblClickDate).format("kk:mm")
        : moment().format("kk:mm"),
      toTime: this.props.dblClickDate
        ? moment(this.props.dblClickDate).add(1, "hours").format("kk:mm")
        : moment().add(1, "hours").format("kk:mm"),
      notes: "",
      notify_email: 0,
      notify_sms: 1,
      selectPatientValue: "",
      selectValue: "",
      validFromTime: false,
      validToTime: false
    });
    this.props.addAppointmentMod(!this.props.showMod);
  };
  checkFromTime = (time) => {
    let temp = new Date(`01/01/2011 ${time}`) < new Date("01/01/2011 7:00");
    this.setState({ validFromTime: temp })
  };
  //check  To time validations
  checkToTime = (time) => {
    let temp = new Date(`01/01/2011 ${time}`) < new Date("01/01/2011 7:00");
    this.setState({ validToTime: temp })
  };
  render() {
    let currentDayTimeValidation;
    if (
      moment().isSameOrAfter(
        moment(`${this.state.date}`, "dddd,DD MMM YYYY"),
        "days"
      )
    ) {
      // current day
      var d = new Date();
      currentDayTimeValidation =
        `${d.getHours()}:${d.getMinutes()}` < this.state.fromTime;
    } else {
      currentDayTimeValidation = true;
    }
    const providerListResult = this.props.providerList.filter(
      (row, index) =>
        (!!row.first_name &&
          row.first_name
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase())) ||
        (!!row.last_name &&
          row.last_name
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase()))
    );
    let providerListOp = providerListResult.map((i) => {
      return { value: i.id, label: `${i.first_name} ${i.last_name}` };
    });
    const clientDetailsResult = this.props.clientDetails.filter(
      (row, index) =>
        (!!row.first_name &&
          row.first_name
            .toLowerCase()
            .includes(this.state.searchPatientValue.toLowerCase())) ||
        (!!row.last_name &&
          row.last_name
            .toLowerCase()
            .includes(this.state.searchPatientValue.toLowerCase()))
    );
    let clientListOp = clientDetailsResult.map((i) => {
      return { value: i.id, label: `${i.first_name} ${i.last_name}` };
    });

    let count = 0;
    // console.log("his.props.servicesList", this.props.servicesList);
    let treatmentOp = this.props.servicesList
      .filter((i) => i.name)
      .map((row, index) => {
        if (row.name) {
          const numberOfSubServices = this.props.subServicesList.filter(
            (subRow, index) =>
              row.id === subRow.parent_id &&
              subRow.enabled === "1" &&
              !!subRow.name &&
              subRow.name
                .toLowerCase()
                .includes(this.state.searchNoteValue.toLowerCase())
          );
          count += numberOfSubServices.length;
          if (numberOfSubServices.length > 0) {
            return {
              label: row.name,
              options: numberOfSubServices.map((subRow, index) => {
                return {
                  label: subRow.name,
                  value: subRow.name,
                };
              }),
            };
          }
        }
        return null;
      });
    let options = treatmentOp.filter((i) => i !== null);
    // console.log("OPTIONS", options);
    const { showTime, time, basic } = this.state;
    return this.props.showMod ? (
      <Modal
        isOpen={this.props.showMod}
        toggle={() => this.handleResetInputValues()}
        className="modal-dialog-centered"
      // onClosed={() => this.handleResetInputValues()}
      >
        <ModalHeader>
          <FormattedMessage id={"LANG_APPOINTMENT"} />
        </ModalHeader>
        <ModalBody>
          <div className="day-selector py-1">
            <Row className="align-items-center">
              <Col md={2} xs={2} sm={2}>
                <ButtonToggle
                  className="btn p-1"
                  color="primary"
                  onClick={this.previewDate}
                >
                  <ChevronLeft size={16} />
                </ButtonToggle>
              </Col>
              <Col md={8} xs={8} sm={8}>
                <Input
                  onChange={() => this.state.addState()}
                  type="text"
                  id="CalendarPageDateSlider"
                  placeholder="timer"
                  disabled
                  value={this.state.date}
                />
              </Col>
              <Col md={2} xs={2} sm={2}>
                <ButtonToggle
                  onClick={this.nextDate}
                  className="btn p-1"
                  color="primary"
                >
                  <ChevronRight size={16} />
                </ButtonToggle>
              </Col>
            </Row>
          </div>

          <FormGroup>
            <Label for="email">
              <FormattedMessage id="LANG_SELECT" />{" "}
              <FormattedMessage id="LANG_DOCTOR" />{" "}
              <span className="text-danger">*</span>
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              theme={selectThemeColors}
              // defaultValue={[colorOptions[2], colorOptions[3]]}
              name="colors"
              placeholder=" "
              options={providerListOp}
              className="react-select"
              classNamePrefix="select"
              value={this.state.selectValue}
              onChange={(e) => this.setState({ selectValue: e })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">
              <FormattedMessage id="LANG_SELECT" />{" "}
              <FormattedMessage id="LANG_PATIENT" />{" "}
              <span className="text-danger">*</span>
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              theme={selectThemeColors}
              // defaultValue={[colorOptions[2], colorOptions[3]]}
              name="colors"
              placeholder=" "
              options={clientListOp}
              className="react-select"
              classNamePrefix="select"
              value={this.state.selectPatientValue}
              onChange={(e) => this.setState({ selectPatientValue: e })}
            />
          </FormGroup>
          <div className="text-center my-2 ">
            <h3>
              <FormattedMessage id="LANG_APPOINTMENT" />{" "}
              <FormattedMessage id="LANG_TIME" />
            </h3>
          </div>
          <div className="ora">
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="email">
                    <FormattedMessage id="LANG_FROM" />{" "}
                    <FormattedMessage id="LANG_TIME" />:
                  </Label>
                  <div>
                    <Flatpickr
                      className='form-control'
                      id='timepicker'
                      options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true
                      }}
                    // onChange={date => setBasic(date)}
                    />
                    {/* {showTime && (
                      <TimeKeeper
                        time={this.state.fromTime}
                        onChange={(newTime) =>{
                          this.setState({ fromTime: newTime.formatted24 })
                        this.checkFromTime(newTime.formatted24)}
                        }
                        onDoneClick={() => this.setState({ showTime: false })}
                        hour24Mode={true}
                        switchToMinuteOnHourSelect
                        closeOnMinuteSelect={true}
                        doneButton={(newTime) => (
                          <div style={{ display: "none" }}>Close</div>
                        )}
                      />
                    )}
                    <Input
                      type="text"
                      id="Telefon"
                      placeholder="Telefon"
                      onFocus={() => this.setState({ showTime: true })}
                      value={this.state.fromTime}
                      onBlur={() => this.setState({ showTime: false })}
                    /> */}
                    {/* {!showTime && (
                          <button
                            className="btn btn-primary"
                            onClick={() => setShowTime(true)}
                          >
                            Show
                          </button>
                        )} */}
                  </div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="password">
                    {" "}
                    <FormattedMessage id="LANG_TO" />{" "}
                    <FormattedMessage id="LANG_TIME" />:
                  </Label>
                  <div>
                    <Flatpickr
                      className='form-control'
                      id='timepicker'
                      options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true
                      }}
                    // onChange={date => setBasic(date)}
                    />
                    {/* {this.state.showTime2 && (
                      <TimeKeeper
                        time={this.state.toTime}
                        onChange={(newTime) =>{
                          this.setState({ toTime: newTime.formatted24 })
                        this.checkToTime(newTime.formatted24)}
                        }
                        onDoneClick={() => this.setState({ showTime2: false })}
                        hour24Mode={true}
                        switchToMinuteOnHourSelect
                        closeOnMinuteSelect={true}
                        doneButton={(newTime) => (
                          <div style={{ display: "none" }}>Close</div>
                        )}
                      />
                    )}
                    <Input
                      type="text"
                      id="Telefon"
                      placeholder="Telefon"
                      onFocus={() => this.setState({ showTime2: true })}
                      value={this.state.toTime}
                      onBlur={() => this.setState({ showTime2: false })}
                    /> */}
                  </div>
                </FormGroup>
              </Col>
              <Col md={12}>
                {this.state.fromTime > this.state.toTime && (
                  <p className="error-text-p" style={{ color: "#f00" }}>
                    {<FormattedMessage id="LANG_ERROR_1" />}
                  </p>
                )}
                {this.state.validFromTime && (
                  <p className="error-text-p" style={{ color: "#f00" }}>
                    {<FormattedMessage id="LANG_ERROR_2" />}
                  </p>
                )}
                {this.state.validToTime && (
                  <p className="error-text-p" style={{ color: "#f00" }}>
                    {<FormattedMessage id="LANG_ERROR_3" />}
                  </p>
                )}
                {!currentDayTimeValidation && (
                  <p className="error-text-p" style={{ color: "#f00" }}>
                    {<FormattedMessage id="LANG_ERROR_4" />}
                  </p>
                )}

                {this.state.validSlot && (
                  <p className="error-text-p" style={{ color: "#f00" }}>
                    {<FormattedMessage id="LANG_ERROR_5" />}
                  </p>
                )}
                {moment(moment(this.state.toTime, "HH:mm")).diff(
                  moment(this.state.fromTime, "HH:mm"),
                  "minutes"
                ) < 15 && (
                    <p className="error-text-p" style={{ color: "#f00" }}>
                      {<FormattedMessage id="LANG_ERROR_11" />}
                    </p>
                  )}
              </Col>
            </Row>
          </div>
          <div className="text-center my-2 ">
            <h3>
              <FormattedMessage id="LANG_NOTIFY" />
            </h3>
          </div>

          <div className="text-center">
            <CustomInput
              type="switch"
              id="allDay"
              name="notify_sms"
              onChange={(e) => this.handleInputChange(e)}
              label={<FormattedMessage id="LANG_SMS" />}
              inline
              checked={this.state.notify_sms === 0 ? false : true}
            />
            <CustomInput
              type="switch"
              id="allDay1"
              name="notify_email"
              label={<FormattedMessage id="LANG_EMAIL" />}
              inline
              onChange={(e) => this.handleInputChange(e)}
              checked={this.state.notify_email === 0 ? false : true}
            />
          </div>
          <FormGroup>
            <Label for="email">
              <FormattedMessage id="LANG_TREATMENT" />
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              theme={selectThemeColors}
              // defaultValue={[colorOptions[2], colorOptions[3]]}
              name="colors"
              placeholder=" "
              options={options}
              value={this.state.notes}
              onChange={(e) => this.setState({ notes: e })}
              className="react-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={this.saveClick}
            disabled={
              !this.state.selectPatientValue ||
              !this.state.selectValue ||
              !currentDayTimeValidation ||
              this.props.providerList.length === 0 ||
              this.props.clientDetails.length === 0 ||
              this.state.fromTime > this.state.toTime ||
              this.state.validFromTime ||
              this.state.validToTime ||
              moment(moment(this.state.toTime, "HH:mm")).diff(
                moment(this.state.fromTime, "HH:mm"),
                "minutes"
              ) < 15
            }
          >
            <FormattedMessage id="LANG_SAVE" />
          </Button>
        </ModalFooter>
      </Modal>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  const { clientDetails, currentClient, appoinmentList, units } = state.client;
  const { servicesList, currentSubServicesList, subServicesList } =
    state.services;
  const { providerList, hygienistList } = state.personnel;
  const { token } = state.auth.userData;
  const { dblClickDate, showMod } = state.modal;
  let providerListResult = [];
  if (providerList.length > 0) {
    providerList.forEach((row) => {
      if (
        (!!row.first_name && row.first_name.toLowerCase().includes("")) ||
        (!!row.last_name && row.last_name.toLowerCase().includes(""))
      ) {
        providerListResult.push(row);
      }
    });
  }
  if (hygienistList.length > 0) {
    hygienistList.forEach((row) => {
      if (
        (!!row.first_name && row.first_name.toLowerCase().includes("")) ||
        (!!row.last_name && row.last_name.toLowerCase().includes(""))
      ) {
        providerListResult.push(row);
      }
    });
  }
  const clientDetailsResult = clientDetails.filter(
    (row, index) =>
      (!!row.first_name && row.first_name.toLowerCase().includes("")) ||
      (!!row.last_name && row.last_name.toLowerCase().includes(""))
  );
  return {
    units,
    providerListResult,
    clientDetailsResult,
    appoinmentList,
    dblClickDate,
    providerList,
    clientDetails,
    token,
    currentClient,
    servicesList,
    currentSubServicesList,
    subServicesList,
    showMod,
  };
};

export default connect(mapStateToProps, {
  officeGetStatsApi,
  appointmentApi,
  changeClientAppointmentFlag,
  dbleClickDate,
  clientApi,
  redCrossApi,
  addSingleAppointment,
  updateCalenderEvent,
  activeServices,
  addSingleAppoinment,
  addAppointmentList,
  updateCalenderFlag,
  addAppointmentMod,
  setLoader,
})(AddAppointment);
