import { toast } from "react-toastify";
import { api } from "../../../api/api";
import { handleLogin, handleLogout } from "../auth";
import {
  officeGetApi,
  setLoader,
  userApi
} from "../../actions";

export const login = (data, history) => {
  return async (dispatch, store) => {
    dispatch(setLoader(true));
    await api("backend_user_login", data, "postWithoutToken").then(
      async (res) => {
        if (res.data.error === 0) {
          toast.success("Autentificare cu succes");
          dispatch(handleLogin(res.data.result));
          dispatch(officeGetApi(res.data.result.token));
          dispatch(userApi());
          dispatch(setLoader(false));
          history.push("/");
        } else {
          dispatch(setLoader(false));
          toast.error(res.data.result.message);
        }
      }
    );
  };
};

export const logout = (history) => {
  return async (dispatch, store) => {
    let token = JSON.parse(localStorage.getItem("dentWare"));
    let version = store().auth.version;
    let formData = new FormData();
    if (token) {
      formData.append("token", token.token);
      formData.append("version", version);
      dispatch(setLoader(true));
      await api("backend_user_logout", formData, "postWithoutToken").then(
        (res) => {
          if (res.data.error === 0) {
            toast.success("Deconectare reușită");
            dispatch(handleLogout());
            dispatch(setLoader(false));
            history.push("/login");
          } else {
            dispatch(setLoader(false));
            toast.error(res.data.result.message);
          }
        }
      );
    }
  };
};
