import {
  SET_STATISTICS_CHARTS,
  SET_STATISTICS_DAYS,
  SET_MOST_APPOINTMENT_DATA
} from "../../storeConfig/types";

/**
 * @function setStatisticsCharts
 * @description set chart data
 */

export const setStatisticsCharts = (type, data) => {
  return {
    type: SET_STATISTICS_CHARTS,
    payload: { type, data }
  };
};

/**
 * @function setStatisticsDays
 * @description set chart days
 */

export const setStatisticsDays = data => {
  return {
    type: SET_STATISTICS_DAYS,
    payload: { data }
  };
};

export const setMostAppointmentData = (name, count) => {
  return {
    type: SET_MOST_APPOINTMENT_DATA,
    payload: { name, count }
  };
};
