import {
  EDIT_PATIENT_FORM_DATA,
  EDIT_PATIENT_MEDICAL_DATA,
} from "../../storeConfig/types";

// ** Initial State
const initialState = {
  tasks: [],
  selectedTask: {},
  searchProviderList: [],
  editPatientFlag: false,
  editPatientFormData: {},
  editPatientMedicalData: [],
  loggedIn: true,
  params: {
    filter: "",
    q: "",
    sort: "",
    tag: "",
  },
};

const PatientReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TASKS":
      return { ...state, tasks: action.tasks, params: action.params };
    case "UPDATE_TASKS":
      return { ...state };
    case "REORDER_TASKS":
      return { ...state, tasks: action.tasks };
    case "SELECT_TASK":
      return { ...state, selectedTask: action.task };
    case "EDIT_PATIENT_FLAG":
      return { ...state, editPatientFlag: action.value };
    case "GET_CALENDER_DOCTOR":
      return { ...state, searchProviderList: action.payload.data };
    case EDIT_PATIENT_FORM_DATA:
      return { ...state, editPatientFormData: action.payload.data };
    case EDIT_PATIENT_MEDICAL_DATA:
      return { ...state, editPatientMedicalData: action.payload.data };
    case "RESET":
      return { loggedIn };
    default:
      return state;
  }
};
export default PatientReducer;
