import {
  SHOW_LOGIN,
  CHANGE_EDIT_SEARCH_FLAG,
  CHANGE_EDIT_CLIENT_FLAG,
  CHANGE_SIGNATURE_FLAG,
  ADD_SIGNATURE,
  SEARCH_CLIENT_LIST,
  ADD_APPOINTMENT_LIST,
  GET_ANSWER_CLIENT,
  GET_COUNTRY_DATA,
  GET_CITY_DATA,
  CHECK_SECOND_STEP_FORM,
  CHECK_FIRST_STEP_FORM,
  UPDATE_TREATMENT_LIST,
  ADD_TREATMENT_LIST,
  ACTIVE_CLIENT,
  CHANGE_TAB,
  ADD_APPOINTMENT,
  NEW_APPOINTMENT,
  SAVE_CLIENT,
  CHANGE_EDIT_BUTTON,
  CHANGE_TEXT_FORM,
  UPDATE_TEXT_FORM_LIST,
  UPDATE_TREATMENT_FORM_LIST,
  SHOW_ADD_DOCTOR,
  GET_QUESTION,
  GET_PATIENT_NEW_FORM_DATA,
  GET_ANSWER,
  ADD_DOCTOR_LIST,
  ADD_DOCTOR,
  SORT_CLIENT,
  ADD_TEXT_FORM,
  ANSWER_ALL_CLIENT,
  ADD_SINGLE_APPOINTMENT,
  SET_PATIENT_LOADING_FLAG,
  SET_CALENDAR_FLAG,
  SET_CLIENT_DEFAULT_STATE,
  SET_CURRENT_CLIENT_FLAG,
  SET_SEARCH_PATIENT_LIST,
  UNSHIFT_SEARCH_PATIENT_LIST,
  RESET_APPOINTMENT_STATE,
  SET_PATIENT_LIST_LOADING_FLAG,
  ADD_WINDOW_HEIGHT,
  SET_APPOINTMENT_UNITS,
  SET_OFFICES_DATA,
  SET_CLIENT_PRINT,
  SET_SIGNATURE_ALL_IMAGE,
  SET_CLIENT_ANS_YES_NO,
  SET_STRIPE_PRICE,
  ADD_CALENDER_APPOINTMENT_LIST,
} from "../../storeConfig/types";

/**
 * @variable INITIAL_STATE
 * @description initial value of client state
 */
const INITIAL_STATE = {
  stripe_details: {
    stripeToken: "pk_live_QfVg2eZnQeARxc20kYVkOF9S",
    currency: "ron",
  },
  clientTab: [
    {
      active: true,
      value: "LANG_CLIENTS_DETAILS",
      id: "details-tab",
    },
    {
      active: false,
      value: "LANG_CLIENTS_CALENDAR",
      id: "calender-tab",
    },
    {
      active: false,
      value: "LANG_CLIENTS_FORMS",
      id: "forms-tab",
    },
    {
      active: false,
      value: "LANG_CLIENTS_XRAY",
      id: "x-ray-tab",
    },
  ],
  editSearchFlag: false,
  editClientFlag: false,
  activeTab: "details-tab",
  editButton: true,
  textForm: false,
  textFormList: [],
  treatmentForm: false,
  treatmentFormList: [],
  treatmentList: [],
  clientDetails: [],
  currentClient: {},
  showAddAppointment: false,
  addnewAppointment: false,
  ShowDoctor: false,
  // selectDoctor:'',
  questionData: [],
  patientNewFormData: {},
  questionAnswer: [],
  firstStepForm: false,
  secondStepForm: false,
  selectDoctor: {},
  doctorList: [],
  assistantList: [],
  hygienistList: [],
  administrators: [],
  sortClient: "ase",
  appoinmentList: [],
  calendarAppointmentList: [],
  searchList: [],
  cityList: [],
  countryList: [],
  answerClient: [],
  answerClientYesNo: [],
  signature: null,
  signatureFlag: false,
  allClientAnswear: [],
  patientLoadingFlag: false,
  calendarFlag: false,
  currentClientFlag: false,
  searchPatientList: [],
  patientListLoadingFlag: false,
  windowHeight: {},
  units: 1,
  officesData: {},
  allsignatureImage: [],
  printClientData: false,
  stripePrice: [],
  languageClient: "ro",
};

/**
 * @function ClientReducer
 * @description update the store according to action
 */
const ClientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_APPOINTMENT_UNITS:
      return { ...state, units: action.payload.data };
    case SET_OFFICES_DATA:
      return { ...state, officesData: action.payload.data };
    case SET_STRIPE_PRICE:
      return { ...state, stripePrice: action.payload.data };
    case CHANGE_EDIT_CLIENT_FLAG:
      return { ...state, editClientFlag: action.payload.data };
    case CHANGE_TAB:
      return {
        ...state,
        clientTab: action.payload.newArray,
        activeTab: action.payload.activeTab,
        textForm: false,
        treatmentForm: false,
      };
    case CHANGE_EDIT_BUTTON:
      return {
        ...state,
        editButton: action.payload.value,
      };
    case CHANGE_TEXT_FORM:
      return {
        ...state,
        [action.payload.formName]: action.payload.value,
      };
    case UPDATE_TEXT_FORM_LIST:
      return {
        ...state,
        [action.payload.formValues]: [
          ...state[action.payload.formValues],
          action.payload.newValue,
        ],
      };
    case UPDATE_TREATMENT_FORM_LIST:
      return {
        ...state,
        [action.payload.formValues]: action.payload.newFormValue,
      };
    case ADD_APPOINTMENT:
      return {
        ...state,
        showAddAppointment: action.payload.addAppointment,
      };
    case NEW_APPOINTMENT:
      return {
        ...state,
        addnewAppointment: action.payload.newAppointmentData,
      };
    case SAVE_CLIENT:
      return {
        ...state,
        clientDetails: action.payload.value.clientDetailsdata,
        searchPatientList: action.payload.searchData,
      };
    case ACTIVE_CLIENT:
      return {
        ...state,
        currentClient: action.payload.currentClient,
        currentClientFlag: true,
        editButton: true,
        textForm: false,
        treatmentForm: false,
      };
    case SHOW_ADD_DOCTOR:
      return {
        ...state,
        ShowDoctor: action.payload.value,
      };
    case ADD_DOCTOR:
      return {
        ...state,
        selectDoctor: action.payload.data,
      };

    case ADD_TREATMENT_LIST: {
      return {
        ...state,
        treatmentList: action.payload.treatmentList,
      };
    }

    case ADD_TEXT_FORM: {
      return {
        ...state,
        textFormList: action.payload.observationList,
      };
    }
    case UPDATE_TREATMENT_LIST: {
      return {
        ...state,
        treatmentList: [...state.treatmentList, action.payload.data],
      };
    }
    case GET_QUESTION: {
      return {
        ...state,
        questionData: action.payload.questionData,
      };
    }
    case GET_PATIENT_NEW_FORM_DATA: {
      return {
        ...state,
        patientNewFormData: {
          ...state.patientNewFormData,
          ...action.payload.data,
        },
      };
    }
    case GET_ANSWER: {
      return {
        ...state,
        questionAnswer: action.payload.data,
      };
    }
    case CHECK_FIRST_STEP_FORM: {
      return {
        ...state,
        firstStepForm: action.payload.value,
      };
    }
    case CHECK_SECOND_STEP_FORM: {
      return {
        ...state,
        secondStepForm: action.payload.value,
      };
    }
    case ADD_DOCTOR_LIST:
      return {
        ...state,
        doctorList: action.payload.value,
      };
    case SORT_CLIENT:
      return {
        ...state,
        sortClient: action.payload.value,
      };
    case ADD_APPOINTMENT_LIST:
      return {
        ...state,
        appoinmentList: action.payload.value,
      };
    case ADD_CALENDER_APPOINTMENT_LIST:
      return {
        ...state,
        calendarAppointmentList: action.payload.value,
      };
    case SEARCH_CLIENT_LIST:
      return {
        ...state,
        searchList: action.payload.value,
      };
    case GET_CITY_DATA:
      return {
        ...state,
        cityList: action.payload.data,
      };
    case GET_COUNTRY_DATA:
      return {
        ...state,
        countryList: action.payload.data,
      };
    case GET_ANSWER_CLIENT:
      return {
        ...state,
        answerClient: action.payload.data,
      };
    case SET_CLIENT_ANS_YES_NO:
      return {
        ...state,
        answerClientYesNo: action.payload.data,
      };
    case ADD_SIGNATURE:
      return {
        ...state,
        signature: action.payload.data,
        signatureFlag: true,
      };
    case CHANGE_SIGNATURE_FLAG:
      return {
        ...state,
        signatureFlag: action.payload.data,
      };
    case ANSWER_ALL_CLIENT:
      return {
        ...state,
        allClientAnswear: action.payload.value,
      };
    case ADD_SINGLE_APPOINTMENT:
      return {
        ...state,
        appoinmentList: [...state.appoinmentList, action.payload.value],
      };
    case SET_PATIENT_LOADING_FLAG:
      return {
        ...state,
        patientLoadingFlag: action.payload.data,
      };
    case SET_CALENDAR_FLAG:
      return {
        ...state,
        calendarFlag: action.payload.data,
      };
    case SET_CURRENT_CLIENT_FLAG:
      return { ...state, currentClientFlag: action.payload.data };
    case SET_SIGNATURE_ALL_IMAGE:
      return { ...state, allsignatureImage: action.payload.data };
    case SET_CLIENT_DEFAULT_STATE:
      return {
        ...state,
        editButton: true,
        addnewAppointment: false,
        ShowDoctor: false,
      };
    case SET_SEARCH_PATIENT_LIST:
      return { ...state, searchPatientList: action.payload.data };
    case UNSHIFT_SEARCH_PATIENT_LIST:
      let tempArr = [...state.searchPatientList];
      tempArr.unshift(action.payload.data);
      return { ...state, searchPatientList: [...tempArr] };
    case RESET_APPOINTMENT_STATE:
      return { ...state, ShowDoctor: false, selectDoctor: {} };
    case SET_PATIENT_LIST_LOADING_FLAG:
      return { ...state, patientListLoadingFlag: action.payload.data };
    case ADD_WINDOW_HEIGHT:
      return { ...state, windowHeight: action.payload.data };
    case CHANGE_EDIT_SEARCH_FLAG:
      return { ...state, editSearchFlag: action.payload.data };
    case SHOW_LOGIN:
      if (!action.payload.showLogindata) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
    case SET_CLIENT_PRINT:
      return { ...state, printClientData: action.payload.data };
    default:
      return state;
  }
};

export default ClientReducer;
