import moment from 'moment';
import { SHOW_LOGIN, SET_APPOINTMENT_STATE, RESET_APPOINTMENT_STATE, CHANGE_CLIENT_APPOINTMENT_FLAG } from "../../storeConfig/types";

/**
 * @variable INITIAL_STATE
 * @description initial value of appointment state
 */
const INITIAL_STATE = {
    clientAppointmentFlag: false,
    pre: '<',
    date: moment().format('dddd, DD MMMM YYYY'),
    fromTime: '10:30',
    toTime: '11:00',
    notes: '',
    notify_email: 0,
    notify_sms: 0
}

/**
 * @function AppointmentReducer
 * @description update the store according to action
 */
const AppointmentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_CLIENT_APPOINTMENT_FLAG:
            return { ...state, clientAppointmentFlag: action.payload.data }
        case SET_APPOINTMENT_STATE:
            return { ...state, [action.payload.prop]: action.payload.value }
        case RESET_APPOINTMENT_STATE:
            return { ...state, ...INITIAL_STATE }
        case SHOW_LOGIN:
            if (!action.payload.showLogindata) {
                return { ...state, ...INITIAL_STATE }
            }
            return { ...state }
        default:
            return state;
    }
};

export default AppointmentReducer;