import {
  SHOW_MODAL,
  ADD_APPOINTMENT_MODAL,
  CURRENT_EVENT_VALUE,
  UPDATE_CALENDER_FLAG,
  UPDATE_CALENDER_EVENT,
  COMPARE_IMAGE_MODAL,
  SET_UPLOAD_MODAL,
  SET_ADD_ONE_APPOINTMENT,
  DUBLE_CLICK_DATE,
  SHOW_CALENDAR_MODAL,
  SET_CALENDAR_MODAL_TYPE,
  ADD_APPOINTMENT_MOD,
  SELECTED_ANYWHERE_FLAG
} from "../../storeConfig/types";

/**
 * @function showView
 * @description Used for set show modal flag
 */
export const showView = value => {
  return {
    type: SHOW_MODAL,
    payload: { value }
  };
};
/**
 * @function showCalendarModal
 * @description Used for set show modal flag
 */
export const showCalendarModal = value => {
  return {
    type: SHOW_CALENDAR_MODAL,
    payload: { value }
  };
};

/**
 * @function setCalendarModalType
 * @description Used for set show modal flag
 */
export const setCalendarModalType = value => {
  return {
    type: SET_CALENDAR_MODAL_TYPE,
    payload: { value }
  };
};

/**
 * @function addAppointmentModal
 * @description Used for set add appointment flag
 */
export const addAppointmentModal = value => {
  return {
    type: ADD_APPOINTMENT_MODAL,
    payload: { value }
  };
};
export const addAppointmentMod = value => {
  return {
    type: ADD_APPOINTMENT_MOD,
    payload: { value }
  };
};

/**
 * @function addAppointmentModal
 * @description Used for set current event
 */
export const addCurrentEventValue = value => {
  return {
    type: CURRENT_EVENT_VALUE,
    payload: { value }
  };
};

/**
 * @function addAppointmentModal
 * @description Used for set current event
 */
 export const SelectedAnyWhereFlag = value => {
  return {
    type: SELECTED_ANYWHERE_FLAG,
    payload: { value }
  };
};
/**
 * @function addAppointmentModal
 * @description Used for update the calendar flag
 */
export const updateCalenderFlag = value => {
  return {
    type: UPDATE_CALENDER_FLAG,
    payload: { data: value }
  };
};

/**
 * @function addAppointmentModal
 * @description Used for update calendar event flag
 */
export const updateCalenderEvent = value => {
  return {
    type: UPDATE_CALENDER_EVENT,
    payload: { value }
  };
};

/**
 * @function addAppointmentModal
 * @description Used for compare image flag
 */
export const setCompareImageModal = value => {
  return {
    type: COMPARE_IMAGE_MODAL,
    payload: { value }
  };
};

/**
 * @function addAppointmentModal
 * @description Used for compare image flag
 */
export const setUploadModal = value => {
  return {
    type: SET_UPLOAD_MODAL,
    payload: { value }
  };
};

export const addSingleAppointment = value => {
  return {
    type: SET_ADD_ONE_APPOINTMENT,
    payload: { value }
  };
};

export const dbleClickDate = value => {
  return {
    type: DUBLE_CLICK_DATE,
    payload: { value }
  };
};
