import { store } from "../redux/storeConfig/store";

const axios = require("axios");

const mainUrl = "https://main.dentware.ro/"; // ro live

export const api = async (endpoint, data, type) => {
  var res;
  switch (type) {
    case "postWithoutToken":
      await axios({
        method: "post",
        data: data,
        headers: {
          "Content-Type": "application/json"
        },
        url: mainUrl + endpoint
      })
        .then(function (response) {
          res = response;
        })
        .catch(err => {
          res = err.response;
        });
      break;
    case "post":
      await axios({
        data: data,
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        url: mainUrl + endpoint
      })
        .then(function (response) {
          res = response;
          // if (res.data.message === "OK" && res.data.result.error === -1) {
          //   localStorage.removeItem("dentWare");
          // }
          if (res.data.message === "OK") {
            if (res.data.result && res.data.result.error === -1) {
              localStorage.removeItem("dentWare");
            }
          }
        })
        .catch(err => {
          console.error(err);
          // if (
          //   err.response.status === 401 ||
          //   err.response.status === 403 ||
          //   err.response.status === 503
          // ) {
          // } else {
          //   res = err.response;
          // }
        });
      break;
    case "postWithFormData":
      await axios({
        data: data,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        url: mainUrl + endpoint
      })
        .then(function (response) {
          res = response;
        })
        .catch(err => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
          } else {
            res = err.response;
          }
        });
      break;
    case "get":
      await axios({
        method: "get",
        headers: {
          "Content-Type": "application/json"
        },
        url: mainUrl + endpoint
      })
        .then(function (response) {
          res = response;
        })
        .catch(err => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem("dentWare");
            store.dispatch(setLoginFlag(false));
            store.dispatch(setLoginUser({}));
            history.push("/login");
          } else {
            res = err.response;
          }
        });
      break;
    case "put":
      await axios({
        method: "put",
        data: data,
        headers: {
          "Content-Type": "application/json"
        },
        url: mainUrl + endpoint
      })
        .then(function (response) {
          res = response;
        })
        .catch(err => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem("dentWare");
            store.dispatch(setLoginFlag(false));
            store.dispatch(setLoginUser({}));
            history.push("/pages/login");
          } else {
            res = err.response;
          }
        });
      break;
    case "delete":
      await axios({
        data: data,
        method: "delete",
        headers: {
          "Content-Type": "application/json"
        },
        url: mainUrl + endpoint
      })
        .then(function (response) {
          res = response;
        })
        .catch(err => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem("dentWare");
            store.dispatch(setLoginFlag(false));
            store.dispatch(setLoginUser({}));
            history.push("/login");
          } else {
            res = err.response;
          }
        });
      break;
    case "patch":
      await axios({
        data: data,
        method: "patch",
        headers: {
          "Content-Type": "application/json"
        },
        url: mainUrl + endpoint
      })
        .then(function (response) {
          res = response;
        })
        .catch(err => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem("dentWare");
            store.dispatch(setLoginFlag(false));
            store.dispatch(setLoginUser({}));
            history.push("/login");
          } else {
            res = err.response;
          }
        });
      break;
    case "patchWithFormData":
      await axios({
        data: data,
        method: "patch",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        url: mainUrl + endpoint
      })
        .then(function (response) {
          res = response;
        })
        .catch(err => {
          if (
            err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 503
          ) {
            localStorage.removeItem("dentWare");
            store.dispatch(setLoginFlag(false));
            store.dispatch(setLoginUser({}));
            history.push("/login");
          } else {
            res = err.response;
          }
        });
      break;
    case "postFileDownload":
      await axios({
        method: "post",
        data: data,
        headers: {
          "Content-Type": "application/json"
        },

        url: mainUrl + endpoint
      })
        .then(function (response) {
          const url = window.URL.createObjectURL(
            new Blob([response.data.data])
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          res = err.response;
        });
      break;
    default:
      return true;
  }

  return res;
};

export const checkData = data => {
  return true;
};
