import { api } from "../../../api/api";
import {
  SET_APPOINTMENT_UNITS,
  SET_OFFICES_DATA,
} from "../../storeConfig/types";

export const officeGetApi = (tkn = "") => {
  return async (dispatch, store) => {
    let token = tkn ? tkn : JSON.parse(localStorage.getItem("dentWare")).token;
    let version = store().auth.version;
    let formData = new URLSearchParams();
    formData.append("token", token);
    formData.append("version", version);
    const officeGetResult = await api("backend_office_get", formData, "post");
    if (
      officeGetResult.data.error === 0 &&
      officeGetResult.data.message === "OK" &&
      officeGetResult.data.result !== null
    ) {
      dispatch({
        type: SET_APPOINTMENT_UNITS,
        payload: { data: parseInt(officeGetResult.data.result.units) },
      });
      await dispatch({
        type: SET_OFFICES_DATA,
        payload: { data: officeGetResult.data.result },
      });
    }
  };
};
