import {
    SHOW_LOGIN,
    SET_XRAY_CURRENT_FOLDER,
    GET_BEFORE_IMAGES,
    GET_AFTER_IMAGES,
    GET_RX_IMAGES,

} from "../../storeConfig/types";

/**
 * @variable INITIAL_STATE
 * @description initial value of xRay state
 */
const INITIAL_STATE = {
    xRayFolders: {
        '/': [
            {
                name: {
                    en:'Before',
                    ro:'Before'
                },
                type: 'folder'
            },
            {
                name: {
                    en:'After',
                    ro:'After'
                },
                type: 'folder'
            },
            {
                name: {
                    en:'Rx',
                    ro:'Rx'
                },
                type: 'folder'
            }
        ],
        'Before': [
            {
                name: {
                    en:'..',
                    ro:'..'
                },
                type: 'folder'
            }
        ],
        'After': [
            {
                name: {
                    en:'..',
                    ro:'..'
                },
                type: 'folder'
            }
        ],
        'Rx': [
            {
                name: {
                    en:'..',
                    ro:'..'
                },
                type: 'folder'
            }
        ]
    },
    currentXRayFolder: '/',
    formModel: false,
    currentFormType: "",
    signutareModal: false,
    sources: '',
    pdfModal: false,
    formList: [],
    selectFileData: {},
    beforeImages: [],
    afterImages: [],
    rxImages: []
}

/**
 * @function XRayReducer
 * @description update the store according to action
 */
const XRayReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_XRAY_CURRENT_FOLDER:
            return { ...state, currentXRayFolder: action.payload.data }
        case GET_BEFORE_IMAGES:
            return { ...state, beforeImages: action.payload.data }
        case GET_AFTER_IMAGES:
            return { ...state, afterImages: action.payload.data }
        case GET_RX_IMAGES:
            return { ...state, rxImages: action.payload.data }
        case SHOW_LOGIN:
            if (!action.payload.showLogindata) {
                return { ...state, ...INITIAL_STATE }
            }
            return { ...state }
        default:
            return state;
    }
};

export default XRayReducer;