import {
  SHOW_LOGIN,
  ADD_SERVICES_LIST,
  GET_SUB_SERVICES_LIST,
  ACTIVE_SERVICES,
  ACTIVE_SUB_SERVICES_LIST,
  SEARCH_DATA_LIST,
  CHANGE_INPUT_FLAG,
  SET_SERVICE_LOADING_FLAG,
  SET_SEARCH_SERVICE_LIST,
  SET_SEARCH_SUB_SERVICE_LIST,
} from "../../storeConfig/types";

/**
 * @variable INITIAL_STATE
 * @description initial value of service state
 */
const INITIAL_STATE = {
  servicesList: [],
  subServicesList: [],
  searchDataList: [],
  currentServices: {},
  currentSubServicesList: [],
  inputFlag: false,
  seviceLoadingFlag: false,
  firstTimeLoad: true,
  searchServiceList: [],
  searchSubServiceList: [],
};

/**
 * @function ServicesReducers
 * @description update the store according to action
 */
const ServicesReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_SERVICES_LIST:
      return {
        ...state,
        servicesList: action.payload.data,
        firstTimeLoad: false,
        searchServiceList: action.payload.data,
      };
    case GET_SUB_SERVICES_LIST:
      return { ...state, subServicesList: action.payload.data };
    case ACTIVE_SERVICES:
      return { ...state, currentServices: action.payload.data };
    case ACTIVE_SUB_SERVICES_LIST:
      return {
        ...state,
        currentSubServicesList: action.payload.data,
        searchSubServiceList: action.payload.enabledService,
      };
    case SEARCH_DATA_LIST:
      return { ...state, searchDataList: action.payload.data };
    case CHANGE_INPUT_FLAG:
      return { ...state, inputFlag: action.payload.data };
    case SET_SERVICE_LOADING_FLAG:
      return { ...state, seviceLoadingFlag: action.payload.data };
    case SET_SEARCH_SERVICE_LIST:
      return { ...state, searchServiceList: action.payload.data };
    case SET_SEARCH_SUB_SERVICE_LIST:
      return { ...state, searchSubServiceList: action.payload.data };
    case SHOW_LOGIN:
      if (!action.payload.showLogindata) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
    default:
      return state;
  }
};

export default ServicesReducers;
