import {
    ADD_SERVICES_LIST,
    GET_SUB_SERVICES_LIST,
    ACTIVE_SUB_SERVICES_LIST,
    ACTIVE_SERVICES,
    SEARCH_DATA_LIST,
    CHANGE_INPUT_FLAG,
    SET_SERVICE_LOADING_FLAG,
    SET_SEARCH_SERVICE_LIST,
    SET_SEARCH_SUB_SERVICE_LIST
} from '../../storeConfig/types';

/**
 * @function addServices
 * @description set the all servies in store
 */

export const addServices = (value) => {
    return (dispatch, store) => {
        if (store().services.firstTimeLoad) {
            dispatch(activeServices(value[0]));
        } else {
            const id = store().services.currentServices.id;
            const currentSubServiceList = store().services.subServicesList.filter((service) => service.parent_id === id);
            dispatch(activeSubServicesList(currentSubServiceList));
        }
        dispatch({
            type: ADD_SERVICES_LIST,
            payload: { data: value }
        });
    }
}

/**
 * @function getSubServiceList
 * @description set all sub-services in store
 */

export const getSubServiceList = value => {
    return (dispatch) => {
        value.sort((a, b) => {
            if (a.id < b.id) {
                return 1;
            } else {
                return -1;
            }
        });
        dispatch({
            type: GET_SUB_SERVICES_LIST,
            payload: { data: value }
        });
    }
}

/**
 * @function getSearchDataList
 * @description set the services which is filterd by search string
 */

export const getSearchDataList = (value) => {
    return {
        type: SEARCH_DATA_LIST,
        payload: { data: value }
    }
}

/**
 * @function activeServices
 * @description detect the current active service
 */

export const activeServices = (value) => {
    return (dispatch, store) => {
        dispatch({
            type: ACTIVE_SERVICES,
            payload: { data: value }
        });
        const currentSubServiceList = store().services.subServicesList.filter((service) => service.parent_id === value.id);
        dispatch(activeSubServicesList(currentSubServiceList));
    }
}

/**
 * @function activeSubServicesList
 * @description detect the current active sub-service
 */

export const activeSubServicesList = value => {
    const enabledService = value.filter((service) => service.enabled !== "0");
    return {
        type: ACTIVE_SUB_SERVICES_LIST,
        payload: { data: value, enabledService }
    }
}

/**
 * @function changeInputFlag
 * @description detect the input in current sub-service name is active or not
 */

export const changeInputFlag = value => {
    return {
        type: CHANGE_INPUT_FLAG,
        payload: { data: value }
    }
}

/**
 * @function setServiceLoadingFlag
 * @description detect the lodaing is active or not
 */

export const setServiceLoadingFlag = value => {
    return {
        type: SET_SERVICE_LOADING_FLAG,
        payload: { data: value }
    }
}

/**
 * @function setSearchServiceList
 * @description set the service list according to search value
 */
export const setSearchServiceList = value => {
    return {
        type: SET_SEARCH_SERVICE_LIST,
        payload: { data: value }
    }
}

/**
 * @function setSearchSubServiceList
 * @description set the sub-service list according to search value
 */
export const setSearchSubServiceList = value => {
    return {
        type: SET_SEARCH_SUB_SERVICE_LIST,
        payload: { data: value }
    }
}