/** @format */

import { api } from "../../../api/api";
import {
  setStatisticsCharts,
  setMostAppointmentData,
} from "../statistic/statisticAction";

export const officeGetStatsApi = (type, days) => {
  return async (dispatch, store) => {
    let token = JSON.parse(localStorage.getItem("dentWare")).token;
    let version = store().auth.version;
    let formData = new URLSearchParams();
    formData.append("token", token);
    formData.append("type", type);
    formData.append("days", days);
    formData.append("provider_id", "");
    formData.append("version", version);
    const officeGetStatsResult = await api(
      "backend_office_get_stats",
      formData,
      "post"
    );
    if (
      officeGetStatsResult.data.error === 0 &&
      officeGetStatsResult.data.message === "OK" &&
      officeGetStatsResult.data.result !== null
    ) {
      dispatch(setStatisticsCharts(type, officeGetStatsResult.data.result));
    }
    return true;
  };
};

export const mostAppointmentApi = () => {
  return async (dispatch, store) => {
    let token = JSON.parse(localStorage.getItem("dentWare")).token;
    let version = store().auth.version;
    let formData = new URLSearchParams();
    formData.append("token", token);
    formData.append("type", 7);
    formData.append("days", 30);
    formData.append("provider_id", "");
    formData.append("version", version);
    const officeGetStatsResult = await api(
      "backend_office_get_stats",
      formData,
      "post"
    );
    if (
      officeGetStatsResult.data.error === 0 &&
      officeGetStatsResult.data.message === "OK" &&
      officeGetStatsResult.data.result !== null
    ) {
      let TopDoctorName = "",
        MostAppointmentCount = 0;
      officeGetStatsResult.data.result.forEach((value) => {
        const appointment_count = parseInt(value.appointment_count);
        if (appointment_count > MostAppointmentCount) {
          TopDoctorName = `${value.provider_first_name} ${value.provider_last_name}`;
          MostAppointmentCount = appointment_count;
        }
      });
      dispatch(setMostAppointmentData(TopDoctorName, MostAppointmentCount));
    }
  };
};
