/**
 * @Sidebar
 */

export const CHANGE_SIDEBAR = "CHANGE_SIDEBAR";
export const ADD_WINDOW_HEIGHT = "ADD_WINDOW_HEIGHT";
export const SET_LOGIN_FLAG = "SET_LOGIN_FLAG";
/**
 * @client Route
 */

export const CHANGE_TAB = "CHANGE_TAB";
export const CHANGE_EDIT_BUTTON = "CHANGE_EDIT_BUTTON";
export const CHANGE_TEXT_FORM = "CHANGE_TEXT_FORM";
export const UPDATE_TEXT_FORM_LIST = "UPDATE_TEXT_FORM_LIST";
export const ADD_TEXT_FORM = "ADD_TEXT_FORM";
export const UPDATE_TREATMENT_FORM_LIST = "UPDATE_TREATMENT_FORM_LIST";
export const ADD_APPOINTMENT = "ADD_APPOINTMENT";
export const SAVE_CLIENT = "SAVE_CLIENT";
export const ACTIVE_CLIENT = "ACTIVE_CLIENT";
export const SHOW_ADD_DOCTOR = "SHOW_ADD_DOCTOR";
export const ADD_DOCTOR = "ADD_DOCTOR";
export const ADD_TREATMENT_LIST = "ADD_TREATMENT_LIST";
export const UPDATE_TREATMENT_LIST = "UPDATE_TREATMENT_LIST";
export const ADD_APPOINTMENT_LIST = "ADD_APPOINTMENT_LIST";
export const GET_QUESTION = "GET_QUESTION";
export const GET_PATIENT_NEW_FORM_DATA = "GET_PATIENT_NEW_FORM_DATA";
export const GET_ANSWER = "GET_ANSWER";
export const CHECK_FIRST_STEP_FORM = "CHECK_FIRST_STEP_FORM";
export const CHECK_SECOND_STEP_FORM = "CHECK_SECOND_STEP_FORM";
export const ADD_DOCTOR_LIST = "ADD_DOCTOR_LIST";
export const SORT_CLIENT = "SORT_CLIENT";
export const SEARCH_CLIENT_LIST = "SEARCH_CLIENT_LIST";
export const GET_CITY_DATA = "GET_CITY_DATA";
export const GET_COUNTRY_DATA = "GET_COUNTRY_DATA";
export const GET_ANSWER_CLIENT = "GET_ANSWER_CLIENT";
export const ADD_SIGNATURE = "ADD_SIGNATURE";
export const CHANGE_SIGNATURE_FLAG = "CHANGE_SIGNATURE_FLAG";
export const ANSWER_ALL_CLIENT = "ANSWER_ALL_CLIENT";
export const SET_CURRENT_CLIENT_FLAG = "SET_CURRENT_CLIENT_FLAG";
export const WIZARD_SAVE_SUCCESS = "WIZARD_SAVE_SUCCESS";
export const SET_SEARCH_PATIENT_LIST = "SET_SEARCH_PATIENT_LIST";
export const UNSHIFT_SEARCH_PATIENT_LIST = "UNSHIFT_SEARCH_PATIENT_LIST";
export const SET_PATIENT_LIST_LOADING_FLAG = "SET_PATIENT_LIST_LOADING_FLAG";
export const CHANGE_EDIT_CLIENT_FLAG = "CHANGE_EDIT_CLIENT_FLAG";
export const SET_APPOINTMENT_UNITS = "SET_APPOINTMENT_UNITS";
export const SET_OFFICES_DATA = "SET_OFFICES_DATA";
export const SET_NEW_FORM_SHOW = "SET_NEW_FORM_SHOW";
export const SET_CLIENT_PRINT = "SET_CLIENT_PRINT";
export const SET_SIGNATURE_ALL_IMAGE = "SET_SIGNATURE_ALL_IMAGE";
export const SET_CLIENT_ANS_YES_NO = "SET_CLIENT_ANS_YES_NO";
export const SET_STRIPE_PRICE = "SET_STRIPE_PRICE";
export const SET_CHANGE_SETTING_SCREEN_TAB = "SET_CHANGE_SETTING_SCREEN_TAB";
export const SET_ALL_LOGO_IMAGE = "SET_ALL_LOGO_IMAGE";
export const ADD_CALENDER_APPOINTMENT_LIST = "ADD_CALENDER_APPOINTMENT_LIST";
export const EDIT_PATIENT_FORM_DATA = "EDIT_PATIENT_FORM_DATA";
export const EDIT_PATIENT_MEDICAL_DATA = "EDIT_PATIENT_MEDICAL_DATA";
/*
 * @ folder Route
 */
export const SET_CURRENT_FOLDER = "SET_CURRENT_FOLDER";
export const SHOW_FORM_MODEL = "SHOW_FORM_MODEL";
export const ADD_SINGLE_APPOINTMENT = "ADD_SINGLE_APPOINTMENT";
export const SET_CURRENT_FORM_TYPE = "SET_CURRENT_FORM_TYPE";
export const SET_SIGNATURE_MODAL = "SET_SIGNATURE_MODAL";
export const SAVE_SOURCES_DIV = "SAVE_SOURCES_DIV";
export const PDF_MODAL_VIEW = "PDF_MODAL_VIEW";
export const SET_FOLDER_LIST = "SET_FOLDER_LIST";
export const SET_FILE_PATH = "SET_FILE_PATH";
export const SET_FORM_TYPE = "SET_FORM_TYPE";
export const SET_SELECT_FILE_DATA = "SET_SELECT_FILE_DATA";
export const PREVENT_STEP = "PREVENT_STEP";
export const SAVE_FORM_PDF = "SAVE_FORM_PDF";
export const USER_FORMS_DATA = "USER_FORMS_DATA";
export const DELETE_CONFIRM_POPUP = "DELETE_CONFIRM_POPUP";
export const FORM_HTML_LIST = "FORM_HTML_LIST";
export const EDIT_FORM = "EDIT_FORM";
export const EDIT_FORM_DATA = "EDIT_FORM_DATA";
export const SET_FORMS_FILE = "SET_FORMS_FILE";
export const SET_PERSONNEL_FILE = "SET_PERSONNEL_FILE";
export const SET_TEMPLATES_FORM = "SET_TEMPLATES_FORM";

/*
 * @ XRay Route
 */
export const SET_XRAY_CURRENT_FOLDER = "SET_XRAY_CURRENT_FOLDER";
export const SET_PATIENT_LOADING_FLAG = "SET_PATIENT_LOADING_FLAG";
export const SET_CALENDAR_FLAG = "SET_CALENDAR_FLAG";
export const SET_CLIENT_DEFAULT_STATE = "SET_CLIENT_DEFAULT_STATE";
export const GET_BEFORE_IMAGES = "GET_BEFORE_IMAGES";
export const GET_AFTER_IMAGES = "GET_AFTER_IMAGES";
export const GET_RX_IMAGES = "GET_RX_IMAGES";
export const SET_UPLOAD_MODAL = "SET_UPLOAD_MODAL";

/**
 * @login Route
 */

export const SHOW_ERROR = "SHOW_ERROR";
export const CHECK_LOGIN = "CHECK_LOGIN";
export const NEW_APPOINTMENT = "NEW_APPOINTMENT";
export const SHOW_LOGIN = "SHOW_LOGIN";
export const LOGIN_SECOND_OBJ = "LOGIN_SECOND_OBJ";

/**
 * @Services Route
 */

export const ADD_SERVICES_LIST = "ADD_SERVICES_LIST";
export const ACTIVE_SERVICES = "ACTIVE_SERVICES";
// export const SEARCH_DATA_LIST = 'SEARCH_DATA_LIST';
export const SET_SERVICE_LOADING_FLAG = "SET_SERVICE_LOADING_FLAG";
export const SET_SEARCH_SERVICE_LIST = "SET_SEARCH_SERVICE_LIST";
export const SET_SEARCH_SUB_SERVICE_LIST = "SET_SEARCH_SUB_SERVICE_LIST";
export const SET_NEW_FORM_TYPE = "SET_NEW_FORM_TYPE";

/**
 * @Calendar Modal
 */
export const SET_CALENDAR_MODAL_TYPE = "SET_CALENDAR_MODAL_TYPE";
export const SHOW_CALENDAR_MODAL = "SHOW_CALENDAR_MODAL";
export const SHOW_MODAL = "SHOW_MODAL";
export const ADD_APPOINTMENT_MODAL = "ADD_APPOINTMENT_MODAL";
export const ADD_APPOINTMENT_MOD = "ADD_APPOINTMENT_MOD";
export const UPDATE_CALENDER_FLAG = "UPDATE_CALENDER_FLAG";
export const UPDATE_CALENDER_EVENT = "UPDATE_CALENDER_EVENT";
export const DUBLE_CLICK_DATE = "DUBLE_CLICK_DATE";

export const CURRENT_EVENT_VALUE = "CURRENT_EVENT_VALUE";
export const SEARCH_DATA_LIST = "SEARCH_DATA_LIST";
export const GET_SUB_SERVICES_LIST = "GET_SUB_SERVICES_LIST";
export const ACTIVE_SUB_SERVICES_LIST = "ACTIVE_SUB_SERVICES_LIST";
export const CHANGE_INPUT_FLAG = "CHANGE_INPUT_FLAG";
export const ACTIVE_SUB_SERVICE = "ACTIVE_SUB_SERVICE";
export const COMPARE_IMAGE_MODAL = "COMPARE_IMAGE_MODAL";

export const SELECTED_ANYWHERE_FLAG = "SELECTED_ANYWHERE_FLAG";

/**
 * @Providers Route
 */

export const GET_ADMINISTRATOR_LIST = "GET_ADMINISTRATOR_LIST";
export const GET_ASSISTANT_LIST = "GET_ASSISTANT_LIST";
export const GET_PROVIDER_LIST = "GET_PROVIDER_LIST";
export const GET_HYGIENIST_LIST = "GET_HYGIENIST_LIST";
export const GET_CURRENT_PERSON = "GET_CURRENT_PERSON";
export const EDIT_PERSONNEL_FLAG = "EDIT_PERSONNEL_FLAG";
export const CHANGE_PERSONNEL_LIST_FLAG = "CHANGE_PERSONNEL_LIST_FLAG";
export const SET_PERSONNEL_LOADING_FLAG = "SET_PERSONNEL_LOADING_FLAG";
export const SET_PERSONNEL_DEFAULT_STATE = "SET_PERSONNEL_DEFAULT_STATE";
export const CHANGE_TYPE = "CHANGE_TYPE";
export const SET_SEARCH_ADMINISTRATOR_LIST = "SET_SEARCH_ADMINISTRATOR_LIST";
export const SET_SEARCH_ASSISTANT_LIST = "SET_SEARCH_ASSISTANT_LIST";
export const SET_SEARCH_PROVIDER_LIST = "SET_SEARCH_PROVIDER_LIST";
export const SET_SEARCH_HYGIENIST_LIST = "SET_SEARCH_HYGIENIST_LIST";
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_COLOR_ID = "SET_COLOR_ID";
export const CHANGE_CURRENT_CLIENT_FLAG = "CHANGE_CURRENT_CLIENT_FLAG";
export const GET_CALENDER_DOCTOR = "GET_CALENDER_DOCTOR";

/**
 * @theme Route
 */

export const SET_DARK_THEME = "SET_DARK_THEME";

/**
 * @appointment Route
 */

export const SET_APPOINTMENT_STATE = "SET_APPOINTMENT_STATE";
export const RESET_APPOINTMENT_STATE = "RESET_APPOINTMENT_STATE";

export const SET_ADD_ONE_APPOINTMENT = "SET_ADD_ONE_APPOINTMENT";

/**
 * @statistics Route
 */

export const SET_STATISTICS_CHARTS = "SET_STATISTICS_CHARTS";
export const SET_STATISTICS_DAYS = "SET_STATISTICS_DAYS";
export const SET_MOST_APPOINTMENT_DATA = "SET_MOST_APPOINTMENT_DATA";
export const CHANGE_CLIENT_APPOINTMENT_FLAG = "CHANGE_CLIENT_APPOINTMENT_FLAG";
export const CHANGE_EDIT_SEARCH_FLAG = "CHANGE_EDIT_SEARCH_FLAG";

/**Loader */
export const SET_LOADER = "SET_LOADER";
export const RESET_STORE = "RESET_STORE";
