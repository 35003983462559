import {
    SET_CHANGE_SETTING_SCREEN_TAB,
    SET_ALL_LOGO_IMAGE
} from '../../storeConfig/types';

/**
 * @function changeTheme
 * @description change the theme of website
 */

export const ChangeSettignTab = value => {
    return {
        type: SET_CHANGE_SETTING_SCREEN_TAB,
        payload: { data:value }
    }
}

export const allLogoImage = value =>{
    return {
        type: SET_ALL_LOGO_IMAGE,
        payload: { data:value }
    }
}