import {
  SHOW_LOGIN,
  SET_STATISTICS_CHARTS,
  SET_STATISTICS_DAYS,
  SET_MOST_APPOINTMENT_DATA
} from "../../storeConfig/types";

/**
 * @variable INITIAL_STATE
 * @description initial value of theme state
 */
const INITIAL_STATE = {
  clientsChart: [],
  appointmentsChart: [],
  smsChart: [],
  imagesChart: [],
  providersChart: [],
  servicesChart: [],
  waitChart: [],
  appointmentDays: 7,
  summaryChart: {},
  topServiceChart: {},
  topDoctor: "",
  topAppointment: 0
};

/**
 * @function StatisticsReducer
 * @description update the store according to action
 */
const StatisticsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STATISTICS_CHARTS:
      const chartType =
        action.payload.type === 1
          ? "clientsChart"
          : action.payload.type === 2
          ? "appointmentsChart"
          : action.payload.type === 3
          ? "waitChart"
          : action.payload.type === 5
          ? "smsChart"
          : action.payload.type === 6
          ? "imagesChart"
          : action.payload.type === 7
          ? "providersChart"
          : action.payload.type === 8
          ? "servicesChart"
          : action.payload.type === 9
          ? "summaryChart"
          : action.payload.type === 10
          ? "topServiceChart"
          : undefined;
      return {
        ...state,
        [chartType]: action.payload.data
      };
    case SET_STATISTICS_DAYS:
      return {
        ...state,
        appointmentDays: action.payload.data
      };
    case SET_MOST_APPOINTMENT_DATA:
      return {
        ...state,
        topDoctor: action.payload.name,
        topAppointment: action.payload.count
      };
    case SHOW_LOGIN:
      if (!action.payload.showLogindata) {
        return { ...state, ...INITIAL_STATE };
      }
      return { ...state };
    default:
      return state;
  }
};

export default StatisticsReducer;
