import moment from "moment";
import { toast } from "react-toastify";
import {
  CHANGE_EDIT_SEARCH_FLAG,
  CHANGE_CLIENT_APPOINTMENT_FLAG,
  CHANGE_EDIT_CLIENT_FLAG,
  COMPARE_IMAGE_MODAL,
  CHANGE_SIGNATURE_FLAG,
  ADD_SIGNATURE,
  SEARCH_CLIENT_LIST,
  ADD_APPOINTMENT_LIST,
  ADD_TEXT_FORM,
  GET_ANSWER_CLIENT,
  GET_COUNTRY_DATA,
  GET_CITY_DATA,
  SORT_CLIENT,
  ADD_DOCTOR_LIST,
  CHECK_SECOND_STEP_FORM,
  CHECK_FIRST_STEP_FORM,
  GET_ANSWER,
  GET_PATIENT_NEW_FORM_DATA,
  GET_QUESTION,
  UPDATE_TREATMENT_LIST,
  ADD_TREATMENT_LIST,
  SHOW_ADD_DOCTOR,
  ADD_DOCTOR,
  ACTIVE_CLIENT,
  CHANGE_TAB,
  ADD_APPOINTMENT,
  NEW_APPOINTMENT,
  CHANGE_EDIT_BUTTON,
  SAVE_CLIENT,
  CHANGE_TEXT_FORM,
  UPDATE_TEXT_FORM_LIST,
  ANSWER_ALL_CLIENT,
  ADD_SINGLE_APPOINTMENT,
  SET_PATIENT_LOADING_FLAG,
  SET_CALENDAR_FLAG,
  SET_CLIENT_DEFAULT_STATE,
  SET_CURRENT_CLIENT_FLAG,
  WIZARD_SAVE_SUCCESS,
  SET_SEARCH_PATIENT_LIST,
  UNSHIFT_SEARCH_PATIENT_LIST,
  SET_PATIENT_LIST_LOADING_FLAG,
  ADD_WINDOW_HEIGHT,
  SET_CLIENT_PRINT,
  SET_SIGNATURE_ALL_IMAGE,
  SET_CLIENT_ANS_YES_NO,
  SET_STRIPE_PRICE,
  ADD_CALENDER_APPOINTMENT_LIST,
} from "../../storeConfig/types";
import { api } from "../../../api/api";
import {
  setcurrentFolder,
  showFormModel,
  setCurrentFormType,
  setSignutareModal,
  pdfModalView,
  setXRayCurrentFolder,
  officeGetStatsApi,
  setLoader,
} from "../index";
// import { showLoading, hideLoading } from "react-redux-loading-bar";

/**
 * @function setClientDefaultState
 * @description reset the initial state when component will unmount
 */
export const setClientDefaultState = () => {
  return {
    type: SET_CLIENT_DEFAULT_STATE,
  };
};

/**
 * @function setCalendarFlag
 * @description used for set the calendar flag
 */
export const setCalendarFlag = (value) => {
  return {
    type: SET_CALENDAR_FLAG,
    payload: { data: value },
  };
};

// /**
//  * @function showLoadingBar
//  * @description show the loading bar
//  */
// export const showLoadingBar = () => {
//   return dispatch => {
//     dispatch(showLoading());
//     dispatch(setPatientLoadingFlag(true));
//   };
// };

// /**
//  * @function hideLoadingBar
//  * @description hide the loading bar
//  */
// export const hideLoadingBar = () => {
//   return dispatch => {
//     dispatch(hideLoading());
//     dispatch(setPatientLoadingFlag(false));
//   };
// };

/**
 * @function: changeTab
 * @description this function called changetab and update active and value
 */

export const changeTab = ({ value, oldArray }) => {
  const tab = oldArray.filter((filter) => filter.id === value);
  const newArray = oldArray.map((filter) => {
    if (filter.value === value) {
      return {
        active: true,
        value: filter.value,
        id: filter.id,
      };
    }
    return {
      active: false,
      value: filter.value,
      id: filter.id,
    };
  });
  return {
    type: CHANGE_TAB,
    payload: { newArray, activeTab: tab[0].id },
  };
};

/**
 * @function: changeEditButton
 * @description button change in client page
 */
export const changeEditButton = ({ value }) => {
  return (dispatch, store) => {
    dispatch({
      type: CHANGE_EDIT_BUTTON,
      payload: { value },
    });
  };
};
// changeTextForm
/**
 * @function: changeForm
 * @description change text and update form in client page
 */
export const changeForm = ({ value, formName }) => {
  return {
    type: CHANGE_TEXT_FORM,
    payload: { value, formName },
  };
};

/**
 * @function: updateForm
 * @description update text and treatment form  list
 */
export const updateForm = ({ value, formName, comment }) => {
  return (dispatch, store) => {
    if (formName === "textForm") {
      updateTextFormList(dispatch, store, value, formName);
    }
    if (formName === "treatmentForm") {
      updateTreatmentFormList(dispatch, store, value, formName, comment);
    }
  };
};

// updateTextForm

/**
 * @function: updateTextFormList
 * @description update observerction form list
 */
const updateTextFormList = (dispatch, store, value, formName) => {
  const ts_created = moment.utc().format("YYYY-MM-DD HH:mm:ss");
  const formValues = formName + "List";
  const client_id = store().client.currentClient.id;
  const newValue = {
    text: value,
    ts_created,
    client_id,
  };
  let apiData = new FormData();
  apiData.append("token", store().auth.userData.token);
  apiData.append("version", store().auth.version);
  apiData.append("client_id", client_id);
  apiData.append("private", 0);
  apiData.append("text", value);

  api("backend_note_add", apiData, "post").then((res) => { });

  dispatch(changeForm({ value: false, formName }));
  dispatch({
    type: UPDATE_TEXT_FORM_LIST,
    payload: { newValue, formValues },
  });
};

/**
 * @function: updateTreatmentFormList
 * @description update treatment form list and save store data
 */
const updateTreatmentFormList = async (
  dispatch,
  store,
  value,
  formName,
  comment
) => {
  const provider_id = store().auth.userData.id;
  const { office_id } = store().auth.userData;
  const client_id = store().client.currentClient.id;
  const apiDataVal = {
    token: store().auth.userData.token,
    client_id,
    service_id: value,
    name: comment,
    checked: 0,
    version: 5,
  };
  const apiData = new FormData();

  for (var key in apiDataVal) {
    apiData.append(key, apiDataVal[key]);
  }
  const res = await api("backend_treatment_add", apiData, "post");
  const data = {
    id: res.data.result.id,
    enabled: "1",
    ts_created: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
    ts_modify: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
    office_id,
    provider_id,
    client_id,
    service_id: value,
    appointment_id: "0",
    name: comment,
    checked: "0",
  };
  dispatch(changeForm({ value: false, formName }));
  dispatch({
    type: UPDATE_TREATMENT_LIST,
    payload: { data },
  });
};

/**
 * @function: addAppointment
 * @description appointment list add
 */
export const addAppointment = (value) => {
  return {
    type: ADD_APPOINTMENT,
    payload: { addAppointment: value },
  };
};

/**
 * @function: newAppointment
 * @description add new appontment
 */
export const newAppointment = (value) => {
  return {
    type: NEW_APPOINTMENT,
    payload: { newAppointmentData: value },
  };
};

/**
 * @function: addClient
 * @description add client from in store
 */
export const addClient = (value) => {
  return (dispatch, store) => {
    if (value.clientDetailsdata.length > 0) {
      const searchData = value.clientDetailsdata.filter((rows) => {
        if (rows.first_name && rows.first_name.toLowerCase().includes("")) {
          return true;
        }
      });
      if (
        !store().client.editClientFlag &&
        !store().appointment.clientAppointmentFlag
      ) {
        if (!store().client.currentClient.id)
          dispatch(activeClient(searchData[0]));
        dispatch(changeClientAppointmentFlag(false));
      } else {
        const activeClientResult = searchData.filter(
          (filterData) => filterData.id === store().client.currentClient.id
        );
        dispatch(activeClient(activeClientResult[0]));
      }
      dispatch({
        type: SAVE_CLIENT,
        payload: { value, searchData },
      });
      dispatch(setCalendarFlag(true));
      dispatch(changeEditClientFlag(false));
    }
  }
};
export const changeClientAppointmentFlag = (data) => {
  return {
    type: CHANGE_CLIENT_APPOINTMENT_FLAG,
    payload: { data },
  };
};

export const changeEditClientFlag = (value) => {
  return {
    type: CHANGE_EDIT_CLIENT_FLAG,
    payload: { data: value },
  };
};

export const changeEditSearchFlag = (value) => {
  return {
    type: CHANGE_EDIT_SEARCH_FLAG,
    payload: { data: value },
  };
};

/**
 * @function: activeClient
 * @description current client save data
 */
export const activeClient = (client) => {
  if (client) {
    return (dispatch, store) => {
      let flag = store().client.currentClient.id === client.id;
      if (store().client.editClientFlag) flag = false;
      dispatch({
        type: COMPARE_IMAGE_MODAL,
        payload: { value: false },
      });
      dispatch(setcurrentFolder("/"));
      dispatch(setXRayCurrentFolder("/"));
      dispatch(setCurrentFormType(false));
      dispatch(setSignutareModal(false));
      dispatch(pdfModalView(false));
      dispatch(showFormModel(false));
      if (!flag)
        dispatch({
          type: ACTIVE_CLIENT,
          payload: { currentClient: client },
        });
    };
  } else {
    console.log("New User");
  }

};

/**
 * @function: ShowDoctor
 * @description add selcet doctore in store
 */
export const ShowDoctor = (value) => {
  return {
    type: SHOW_ADD_DOCTOR,
    payload: { value },
  };
};

/**
 * @function: AddDoctor
 * @description add doctore list
 */
export const AddDoctor = ({ data }) => {
  return {
    type: ADD_DOCTOR,
    payload: { data },
  };
};

/**
 * @function: addTreatmentList
 * @description add treatment list in store
 */
export const addTreatmentList = ({ treatmentList }) => {
  return {
    type: ADD_TREATMENT_LIST,
    payload: { treatmentList },
  };
};

/**
 * @function: addObservation
 * @description add data in onservation list
 */
export const addObservation = ({ observationList }) => {
  return {
    type: ADD_TEXT_FORM,
    payload: { observationList },
  };
};

/**
 * @function: saveWizard
 * @description  Add New Patient for and api call
 */
export const saveWizard = (login, fieldData, questionData) => {
  return async (dispatch, store) => {
    dispatch(setLoader(true));
    let userVal = {
      token: store().auth.userData.token,
      login,
      password: ``,
      type: 4,
      color_id: 0,
      version: store().auth.version,
    };
    const userAddData = new FormData();
    for (var key in userVal) {
      userAddData.append(key, userVal[key]);
    }
    // api call
    await api("backend_user_add", userAddData, "post")
      .then(async (res) => {
        if (res.data.error === 0) {
          const savedId = res.data.result.id;
          const formDataToUpload = store().client.signature;
          formDataToUpload.append("version", "5");
          formDataToUpload.append("token", store().auth.userData.token);
          formDataToUpload.append("category_id", "4");
          formDataToUpload.append("provider_id", store().auth.userData.id);
          formDataToUpload.append("client_id", res.data.result.id);
          formDataToUpload.append("appointment_id", "");
          formDataToUpload.append("name", "image");
          await api("backend_image_add", formDataToUpload, "post")
            .then(async (backendimageAddResult) => {
              if (backendimageAddResult.data.error === 0) {
                const userSetVal = {
                  version: "5",
                  token: store().auth.userData.token,
                  id: res.data.result.id,
                  patient_id: "",
                  first_name: "",
                  last_name: "",
                  country_id: "",
                  city_id: "",
                  address: "",
                  idn: "",
                  cnp: "",
                  company: "",
                  profession: "",
                  signature_id: backendimageAddResult.data.result.id,
                  gender: 0,
                  dob: "",
                  reminder: 0,
                  ins_name: "",
                  ins_relation: "",
                  ins_dob: "",
                  ins_company: "",
                  ins_phone: "",
                  ins_subscriber_id: "",
                  ins_group_no: "",
                  ins_secondary: 0,
                  ...fieldData,
                };
                const userSetData = new FormData();
                for (var key in userSetVal) {
                  userSetData.append(key, userSetVal[key]);
                }
                let object = {};
                userSetData.forEach(function (value, key) {
                  object[key] = value;
                });
                let json = JSON.parse(JSON.stringify(object));
                // const imageGetVal = {
                //   version: "5",
                //   token: store().auth.userData.token,
                //   category_id: 4,
                //   client_id: "",
                //   appointment_id: "",
                //   timestamp: "",
                // };

                // FIXME if not necessary remove it
                // const imageGetList = new FormData();
                // for (var key in imageGetVal) {
                //   imageGetList.append(key, imageGetVal[key]);
                // }
                // const imageGetListResult = await api(
                //   "backend_image_get_list",
                //   imageGetList,
                //   "post"
                // );
                // if (
                //   imageGetListResult.data.error === 0 &&
                //   imageGetListResult.data.message === "OK" &&
                //   imageGetListResult.data.result !== null
                // ) {
                //   dispatch(setSignutareImage(imageGetListResult.data.result));
                // }
                await api("backend_user_set_data", userSetData, "post")
                  .then(async (result) => {
                    if (result.data.error === 0) {
                      if (questionData.length !== 0) {
                        const question_Val = {
                          version: "5",
                          token: store().auth.userData.token,
                          user_id: res.data.result.id,
                          answer: JSON.stringify(questionData),
                        };
                        const question_set = new FormData();
                        for (var key in question_Val) {
                          question_set.append(key, question_Val[key]);
                        }
                        await api("backend_question_set", question_set, "post")
                          .then(async (questionSetResult) => {
                            dispatch(
                              activeClient({ ...json, id: savedId, client_id: savedId })
                            );
                            dispatch(unshiftSearchPatientList({ ...json, id: savedId, client_id: savedId }))

                            dispatch({ type: WIZARD_SAVE_SUCCESS });
                            // if (questionSetResult.data.result.error !== -1) {
                            //   var val = {
                            //     version: "5",
                            //     token: store().auth.userData.token,
                            //     type: 4,
                            //     data: 1,
                            //     timestamp: "",
                            //   };
                            // const data = new FormData();
                            // for (var key in val) {
                            //   data.append(key, val[key]);
                            // }
                            // await api("backend_user_get_list", data, "post")
                            //   .then(async (parsedata) => {
                            //     if (parsedata.data.error === 0) {
                            //       var clientDetailsdata =
                            //         parsedata.data.result;
                            //       const payload = {
                            //         version: 5,
                            //         token: store().auth.userData.token,
                            //         user_id: "",
                            //         timestamp: "",
                            //       };
                            //       const adata = new FormData();
                            //       for (var key in payload) {
                            //         adata.append(key, payload[key]);
                            //       }
                            //       await api(
                            //         "backend_question_get_answers",
                            //         adata,
                            //         "post"
                            //       )
                            //         .then(async (answerData) => {
                            //           if (
                            //             ![
                            //               answerData.data.result,
                            //             ].hasOwnProperty("error")
                            //           ) {
                            //             dispatch(
                            //               allQuestAnswer(
                            //                 answerData.data.result
                            //               )
                            //             );
                            //             dispatch(setCurrentClientFlag(true));
                            //             dispatch(
                            //               setPatientLoadingFlag(false)
                            //             );
                            //           }
                            //           setRedCross(
                            //             clientDetailsdata,
                            //             dispatch,
                            //             store
                            //           );
                            //           toast.success(
                            //             "Patient Added Successfully",
                            //             {
                            //               position: toast.POSITION.TOP_RIGHT,
                            //             }
                            //           );
                            //         })
                            //         .catch(() => {
                            //           toast.error("Patient Added Failed", {
                            //             position: toast.POSITION.TOP_RIGHT,
                            //           });
                            //         });
                            //     }
                            //   })
                            //   .catch(() => {
                            //     toast.error("Patient Added Failed", {
                            //       position: toast.POSITION.TOP_RIGHT,
                            //     });
                            //   });
                            // }
                          })
                          .catch(() => {
                            toast.error("Patient Added Failed", {
                              position: toast.POSITION.TOP_RIGHT,
                            });
                          });
                      }
                      if (questionData.length === 0) {
                        dispatch({ type: WIZARD_SAVE_SUCCESS });
                        var qes = {
                          version: "5",
                          token: store().auth.userData.token,
                          type: 4,
                          data: 1,
                          timestamp: "",
                        };
                        const data = new FormData();
                        for (var key in qes) {
                          data.append(key, qes[key]);
                        }
                        // FIXME
                        // await api("backend_user_get_list", data, "post")
                        //   .then(async (parsedata) => {
                        //     if (parsedata.data.error === 0) {
                        //       var clientDetailsdata = parsedata.data.result;
                        //       const bdata = {
                        //         version: 5,
                        //         token: store().auth.userData.token,
                        //         user_id: "",
                        //         timestamp: "",
                        //       };
                        //       const adata = new FormData();
                        //       for (var key in bdata) {
                        //         adata.append(key, bdata[key]);
                        //       }
                        //       await api(
                        //         "backend_question_get_answers",
                        //         adata,
                        //         "post"
                        //       ).then((answerData) => {
                        //         if (
                        //           ![answerData.data.result].hasOwnProperty(
                        //             "error"
                        //           )
                        //         ) {
                        //           dispatch(
                        //             allQuestAnswer(answerData.data.result)
                        //           );
                        //           dispatch(setCurrentClientFlag(true));
                        //           dispatch(setPatientLoadingFlag(false));
                        //         }
                        //         setRedCross(clientDetailsdata, dispatch, store);
                        //         toast.success("Patient Added Successfully", {
                        //           position: toast.POSITION.TOP_RIGHT,
                        //         });
                        //       });
                        //     }
                        //   })
                        //   .catch(() => {
                        //     toast.error("Patient Added Failed", {
                        //       position: toast.POSITION.TOP_RIGHT,
                        //     });
                        //   });
                      }
                      // dispatch(officeGetStatsApi(9, 365));
                    }
                  })
                  .catch(() => {
                    toast.error("Patient Added Failed", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  });
              }
            })
            .catch((e) => {
              console.log(e);
              toast.error("Patient Added Failed", {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error("Patient Added Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    dispatch(addAppointment(false));
    dispatch(setLoader(false));
  };
};

/**
 * @function: addDoctorList
 * @description store Doctore list
 */
export const addDoctorList = (value) => {
  return {
    type: ADD_DOCTOR_LIST,
    payload: { value },
  };
};

export const sortdata = (value) => {
  return {
    type: SORT_CLIENT,
    payload: { value },
  };
};

/**
 * @function: addAppointmentList
 * @description appointment list add in store
 */
export const addAppointmentList = (value) => {
  return {
    type: ADD_APPOINTMENT_LIST,
    payload: { value },
  };
};

export const addCalendarAppointmentList = (value) => {
  return {
    type: ADD_CALENDER_APPOINTMENT_LIST,
    payload: { value },
  };
};
/**
 * @function: addSearchDataList
 * @description search client list data
 */
export const addSearchDataList = (value) => {
  return {
    type: SEARCH_CLIENT_LIST,
    payload: { value },
  };
};

/**
 * @function: getQuestion
 * @description question list add
 */
export const getQuestion = ({ questionData }) => {
  return {
    type: GET_QUESTION,
    payload: { questionData },
  };
};

/**
 * @function: getPatientNewFormdata
 * @description store the value of wizrad form
 */
export const getPatientNewFormdata = (type) => {
  return (dispatch, store) => {
    const data = store().form.wizard.values;
    if (type === "question") {
      let questionAnswer = [];

      Array.prototype.isNull = function () {
        return this.join().replace(/,/g, "").length === 0;
      };
      store().client.questionData.forEach((question) => {
        if (data.hasOwnProperty(`question${question.id}`)) {
          questionAnswer.push({
            id: question.id,
            answer:
              question.multiple === "1"
                ? data[`question${question.id}`].isNull()
                  ? 0
                  : 1
                : data[`question${question.id}`] === true
                  ? 1
                  : 0,
            options:
              question.multiple === "1"
                ? data[`question${question.id}`]
                  .map((answer, index) => {
                    if (answer === true) {
                      return index;
                    }
                  })
                  .filter((element) => element !== undefined)
                : [],
            extra: "",
          });
        }
      });

      dispatch({
        type: GET_ANSWER,
        payload: { data: questionAnswer },
      });
    } else {
      dispatch({
        type: GET_PATIENT_NEW_FORM_DATA,
        payload: { data },
      });
    }
  };
};

/**
 * @function: checkFirstStepForm
 * @description value store first step in add Patient form
 */
export const checkFirstStepForm = (value) => {
  return {
    type: CHECK_FIRST_STEP_FORM,
    payload: { value },
  };
};

/**
 * @function: checkSecondStepForm
 * @description check second step active or not
 */
export const checkSecondStepForm = (value) => {
  return {
    type: CHECK_SECOND_STEP_FORM,
    payload: { value },
  };
};

/**
 * @function: getCityData
 * @description city list dat add
 */
export const getCityData = ({ cityData }) => {
  return {
    type: GET_CITY_DATA,
    payload: { data: cityData },
  };
};

/**
 * @function: getCountryData
 * @description country list data add
 */
export const getCountryData = ({ countryData }) => {
  return {
    type: GET_COUNTRY_DATA,
    payload: { data: countryData },
  };
};

/**
 * @function: getAnswerClient
 * @description select client answer data save
 */
export const getAnswerClient = ({ answerData }) => {
  return {
    type: GET_ANSWER_CLIENT,
    payload: { data: answerData },
  };
};
/**
 * @function: getAnswerClientYesNo
 * @description select client answer data save
 */
export const getAnswerClientYesNo = ({ answerData }) => {
  return {
    type: SET_CLIENT_ANS_YES_NO,
    payload: { data: answerData },
  };
};

/**
 * @function: submitEditFormData
 * @description update client edit form and api call
 */
export const submitEditFormData = () => {
  return (dispatch, store) => {
    dispatch(setLoader(true));
    let data = store().patient.editPatientFormData;
    let formData = new FormData();
    formData.append("token", data.token);
    formData.append("id", data.id);
    formData.append("signature_id", data.signature_id);
    formData.append("reminder", data.reminder);
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("phone", data.phone);
    formData.append("idn", data.idn);
    formData.append("cnp", data.cnp);
    formData.append("dob", data.dob);
    formData.append("gender", data.gender);
    formData.append("address", data.address);
    formData.append("city_id", data.city_id);
    formData.append("country_id", data.country_id);
    formData.append("profession", data.profession);
    formData.append("company", data.company);
    formData.append("patient_id", data.id);
    formData.append("ins_name", data.ins_name);
    formData.append("ins_relation", data.ins_relation);
    formData.append("ins_dob", data.ins_dob);
    formData.append("ins_company", data.ins_company);
    formData.append("ins_phone", data.ins_phone);
    formData.append("ins_subscriber_id", data.ins_subscriber_id);
    formData.append("ins_group_no", data.ins_group_no);
    formData.append("ins_secondary", data.ins_secondary ? "1" : "0");
    formData.append("version", data.version);

    api("backend_user_set_data", formData, "post")
      .then((res) => {
        if (res.data.error === 0) {
          let setAnswerData = new FormData();
          setAnswerData.append("token", data.token);
          setAnswerData.append("version", data.version);
          setAnswerData.append("user_id", data.id);
          setAnswerData.append(
            "answer",
            JSON.stringify(store().patient.editPatientMedicalData)
          );
          api("backend_question_set", setAnswerData, "post")
            .then(async (answer_data) => {
              if (answer_data.data.error === 0) {
                await dispatch(officeGetStatsApi(9, 365));
                let getListData = new FormData();
                getListData.append("version", data.version);
                getListData.append("token", data.token);
                getListData.append("type", 4);
                getListData.append("data", 1);
                getListData.append("timestamp", "");
                // api("backend_user_get_list", getListData, "post")
                //   .then((parsedata) => {
                //     if (parsedata.data.error === 0) {
                //       var clientDetailsdata = parsedata.data.result;
                //       let adata = new FormData();
                //       adata.append("version", data.version);
                //       adata.append("token", data.token);
                //       adata.append("user_id", "");
                //       adata.append("timestamp", "");

                //       api("backend_question_get_answers", adata, "post")
                //         .then((answerData) => {
                //           if (
                //             ![answerData.data.result].hasOwnProperty("error")
                //           ) {
                //             dispatch(allQuestAnswer(answerData.data.result));
                //             dispatch(setCurrentClientFlag(true));
                //           }
                //           setRedCross(clientDetailsdata, dispatch, store);
                //           dispatch(changeEditSearchFlag(true));
                //           toast.success("Patient Updated Successfully!", {
                //             position: toast.POSITION.TOP_RIGHT,
                //           });
                //           dispatch(setLoader(false));
                //         })
                //         .catch((e) => {
                //           toast.error("Patient Edited Failed", {
                //             position: toast.POSITION.TOP_RIGHT,
                //           });
                //           dispatch(setLoader(false));
                //         });
                //     }
                //   })
                //   .catch((e) => {
                //     toast.error("Patient Edited Failed", {
                //       position: toast.POSITION.TOP_RIGHT,
                //     });
                //     dispatch(setLoader(false));
                //   });
                dispatch(setLoader(false));
              }
            })
            .catch((e) => {
              toast.error("Patient Edited Failed", {
                position: toast.POSITION.TOP_RIGHT,
              });
              dispatch(setLoader(false));
            });
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error("Patient Edited Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(setLoader(false));
      });
  };
};

/**
 * @function setRedCross
 * @description used for set red(+) icon
 */
const setRedCross = async (clientdata, dispatch, store) => {
  const email_and_sms = store().client.appoinmentList.filter((value) => {
    if (value.notify_email == 1 || value.notify_sms == 1) return true;
  });
  for (var i = 0; i < clientdata.length; i++) {
    var clientId = clientdata[i].id;
    if (
      store().client.allClientAnswear &&
      store().client.allClientAnswear[clientId]
    ) {
      for (
        var j = 0;
        j < store().client.allClientAnswear[clientId].length;
        j++
      ) {
        var answear_data = store().client.allClientAnswear[clientId];
        if (answear_data[j].user_answer == 1) {
          for (var k = 0; k < store().client.questionData.length; k++) {
            if (store().client.questionData[k].alert == 1) {
              clientdata[i]["redAlert"] = 1;
              break;
            }
          }
        }
      }
    }

    for (var l = 0; l < email_and_sms.length; l++) {
      if (clientdata[i].id == email_and_sms[l].client_id) {
        if (email_and_sms[l].notify_email == 1) {
          clientdata[i]["notify_email"] = 1;
        }
        if (email_and_sms[l].notify_sms == 1) {
          clientdata[i]["notify_sms"] = 1;
        }
      }
    }
  }

  var clientDetailsdata = clientdata;
  dispatch(addClient({ clientDetailsdata }));
};

/**
 * @function: addSignature
 * @description signature data add
 */

export const addSignature = (value) => {
  return (dispatch, store) => {
    // const sig = {
    //   token: store().login.loginData.token,
    //   category_id: 5,
    //   provider_id: "",
    //   client_id: "",
    //   appointment_id: "",
    //   name: "",
    //   image: value
    // };

    dispatch({
      type: ADD_SIGNATURE,
      payload: { data: value },
    });
  };
};

/**
 * @function: changeSignatureFlag
 * @description signature save button change flag
 */
export const changeSignatureFlag = (value) => {
  return {
    type: CHANGE_SIGNATURE_FLAG,
    payload: { data: value },
  };
};

/**
 * @function: allQuestAnswer
 * @description list all client question answer store
 */
export const allQuestAnswer = (value) => {
  return {
    type: ANSWER_ALL_CLIENT,
    payload: { value },
  };
};

/**
 * @function: addSingleAppoinment
 * @description Add single Appoinment add
 */
export const addSingleAppoinment = (value) => {
  return {
    type: ADD_SINGLE_APPOINTMENT,
    payload: { value },
  };
};

/**
 * @function setPatientLoadingFlag
 * @description Used for show loading flag in client route
 */
export const setPatientLoadingFlag = (value) => {
  return {
    type: SET_PATIENT_LOADING_FLAG,
    payload: { data: value },
  };
};

/**
 * @function setCurrentClientFlag
 * @description Used for set current client
 */
export const setCurrentClientFlag = (value) => {
  return {
    type: SET_CURRENT_CLIENT_FLAG,
    payload: { data: value },
  };
};

/**
 * @function setSearchPatientList
 * @description Used for filter patient list according to search value
 */
export const setSearchPatientList = (value) => {
  return {
    type: SET_SEARCH_PATIENT_LIST,
    payload: { data: value },
  };
};

export const unshiftSearchPatientList = (value) => {
  return {
    type: UNSHIFT_SEARCH_PATIENT_LIST,
    payload: { data: value },
  };
};

export const setPatientListLoadingFlag = (value) => {
  return {
    type: SET_PATIENT_LIST_LOADING_FLAG,
    payload: { data: value },
  };
};

export const addWindowHeight = (value) => {
  return {
    type: ADD_WINDOW_HEIGHT,
    payload: { data: value },
  };
};

export const setPrintClient = (value) => {
  return {
    type: SET_CLIENT_PRINT,
    payload: { data: value },
  };
};

export const setSignutareImage = (value) => {
  return {
    type: SET_SIGNATURE_ALL_IMAGE,
    payload: { data: value },
  };
};

export const setStripePrice = (value) => {
  return {
    type: SET_STRIPE_PRICE,
    payload: { data: value },
  };
};
